<template>

    <html>
    <head>
        <meta charset="utf-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <meta name="author" content="Theme Region">
        <meta name="description" content="">
        <title>Daily parking</title>
        <!-- Bootstrap CSS -->
        <link href="https://fonts.googleapis.com/css?family=Open+Sans" rel="stylesheet">
        <link href="css/animate/animate.min.css" rel="stylesheet">
        <link rel="stylesheet" href="css/bootstrap.css">
        <!--	bootstrap css-->
        <!--  CSS -->
        <link rel="stylesheet" href="css/navigation-temp.css">
        <link rel="stylesheet" href="css/style.css">
        <!-- <link rel="stylesheet" href="css/style2.css"> -->

        <link rel="stylesheet" href="css/form.css">
    </head>

    <body>
    <!-- header -->
    <!--		navigation bar -->
    <div class="inline-search-block fixed with-close big" id="toogle-search-bar">
        <div class="maxwidth-theme" >
            <div class="col-md-12">
                <div class="search-wrapper">
                    <div id="title-search">
                        <form action="#" class="search">
                            <div class="search-input-div">
                                <input class="search-input" id="title-search-input" type="text" name="q" value="" placeholder="Search" size="20" maxlength="50" autocomplete="off">
                            </div>
                            <div class="search-button-div">
                                <button class="btn btn-search btn-default bold btn-lg" type="submit" name="s" value="services">Search</button>
                                <span class="close-block inline-search-hide"><span class="svg svg-close close-icons"></span></span> </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="header_wrap visible-lg visible-md title-v3 index">
        <header id="header">
            <div class="header-v4 header-wrapper">
                <div class="logo_and_menu-row container">
                    <div class="logo-row">
                        <div class="maxwidth-theme">
                            <div class="row d-flex justify-content-center justify-content-between">
                                <div class="logo-block col-md-5 col-sm-5 p-0">
                                    <div class="logo">
                                        <a href="javascript: void(0);" @click="gotTo('/')"><img src="img/logo/county_logo.png" alt="e-PayNairobi" class="e-logo mr-3" title="HOME"></a>
                                        <div class="d-flex flex-column">
                                            <h4 class="p-0 mb-1 font-14 fw-semibold">Nairobi County Government</h4>
                                            <span class="p-0 m-0">ePayments Platforms</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="right-icons pull-right float-right ">
                                    <div class="pull-right block-link"></div>
                                    <span><i class="fa icon-font"></i></span>
                                    <div class="pull-right">
                                        <div class="wrap_icon inner-table-block">
                                            <div class="profile-pic d-none logged-in">
                                               <!-- <img src="../images/Invisible-Man-780294.jpg" height="80px" width="80px"><i class="fa fa-angle-down"></i>-->
                                                <ul class="dropdown">
                                                    <li class="menu_back"> <!--<img src="../images/Invisible-Man-780294.jpg" height="40px" width="40px">-->
                                                        <ul>
                                                            <li>email@email.com</li>
                                                            <li>Username</li>
                                                        </ul>
                                                    </li>
                                                    <li> <a class="dark-color" href="#" title="check out your profile details"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Profile</font></font></span> </a> </li>
                                                    <!--my profile links-->
                                                    <li> <a class="dark-color" href="#" title="My ads"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">My Ads</font><span class="count-item">0</span></font></span> </a> </li>
                                                    <!--my profile links-->
                                                    <li> <a class="dark-color" href="#" title="Your Added shops"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">My Shop</font><span class="count-item">0</span></font></span> </a> </li>
                                                    <!--my profile links-->
                                                    <li> <a class="dark-color" href="#" title="Your Favourite Ads"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Favourite Ads</font><span class="count-item">0</span></font></span> </a> </li>
                                                    <!--my profile links-->
                                                    <li> <a class="dark-color" href="#" title="Archived Ads"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Archived Ads</font></font></span> </a> </li>
                                                    <!--my profile links-->
                                                    <li> <a class="dark-color" href="#" title="Pending Approval"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Pending Approval</font><span class="count-item">0</span></font></span> </a> </li>
                                                    <!--my profile links-->
                                                    <li> <a class="dark-color" href="#" title="Close Account"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Close Account</font></font></span> </a> </li>
                                                    <!--my profile links-->
                                                    <li> <a class="dark-color" href="#" title="Close Account"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Log Out</font></font></span> </a> </li>
                                                </ul>

                                                    <li>email@email.com</li>
                                                    <li>Username</li>
                                            </div>
                                        </div>

                                        <div class="wrap_icon inner-table-block">
                                            <div id="bxdynamic_header-auth-block1_start"></div>
                                            <!-- noindex --><a rel="nofollow" title="log in" class="personal-link dark-color animate-load" data-event="jqm" data-param-type="auth" data-param-backurl="/" data-name="auth" href="signin.html"><i class="svg inline big svg-inline-cabinet" aria-hidden="true" title="log in">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">

                                                <path data-name="Rounded Rectangle 110" class="loccls-1" d="M1433,132h-15a3,3,0,0,1-3-3v-7a3,3,0,0,1,3-3h1v-2a6,6,0,0,1,6-6h1a6,6,0,0,1,6,6v2h1a3,3,0,0,1,3,3v7A3,3,0,0,1,1433,132Zm-3-15a4,4,0,0,0-4-4h-1a4,4,0,0,0-4,4v2h9v-2Zm4,5a1,1,0,0,0-1-1h-15a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h15a1,1,0,0,0,1-1v-7Zm-8,3.9v1.6a0.5,0.5,0,1,1-1,0v-1.6A1.5,1.5,0,1,1,1426,125.9Z" transform="translate(-1415 -111)"></path>
                                            </svg>
                                        </i><span class="wrap"><span class="name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Sign in </font></font></span><span class="title"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">My Account</font></font></span></span></a><!-- /noindex -->
                                            <div id="bxdynamic_header-auth-block1_end" style="display:none"></div>
                                        </div>
                                    </div>
                                    <div class="pull-right">
                                        <div class="wrap_icon inner-table-block btn-span-margin text-capitalize"> <a href="#" ><span id="get-a-receipt">print receipt</span></a> <a href="shop-category.html"><span id="add-shop" class="btn-secondary text-black">create an account</span></a> </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="menu-row middle-block bgcolored ">
                    <div class="maxwidth-theme" style="background-color: #005c96;">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="menu-only">
                                        <nav class="mega-menu sliced ovisible initied">
                                            <div class="table-menu">
                                                <table>
                                                    <tbody>
                                                    <tr>
                                                        <td class="menu-item dropdown catalog wide_menu" style="visibility: visible;"><div class="wrap"> <a class="dropdown-toggle" href="/catalog/">
                                                            <div><font style="vertical-align: inherit;"><font style="vertical-align: inherit;"> Services</font></font>
                                                                <div class="line-wrapper"><span class="line"></span></div>
                                                            </div>
                                                        </a> <span class="tail"></span>
                                                            <ul class="dropdown-menu text-capitalize">
                                                                <li class="dropdown-submenu  has_img">
                                                                    <div class="menu_img"><img src="img/new-icons/case.svg" alt="cars & vehcles" title="cars & vehcles"></div>
                                                                    <div  title="county parking services" class="service-link-title pb-4"> <span class="name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Single Business Permits</font></font> </span> <span class="arrow"><i></i></span></div>
                                                                    <ul class="dropdown-menu toggle_menu text-capitalize">
                                                                        <!--									revenue stream service-->
                                                                        <li class="menu-item "> <a href="renew-biz.html" title="renew your business permits" style="white-space: normal;" > <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">renew SBP</font> </font> </span> </a> </li>
                                                                        <!--									revenue stream service-->
                                                                        <!--									revenue stream service-->
                                                                        <li class="menu-item"> <a href="create-biz.html" title="pay or check for penalties" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">register new business</font> </font> </span> </a> </li>
                                                                        <!--									revenue stream service-->

                                                                        <!--									revenue stream service-->
                                                                        <li class="menu-item"> <a href="print-permit.html" title="print a permit" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">print permit</font> </font> </span> </a> </li>
                                                                        <!--									revenue stream service-->

                                                                        <!--									revenue stream service-->
                                                                        <li class="menu-item"> <a href="#" title="print receipt for specific permit" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">print receipt</font> </font> </span> </a> </li>
                                                                        <!--									revenue stream service-->
                                                                    </ul>
                                                                </li>
                                                                <li class="dropdown-submenu  has_img">
                                                                    <div class="menu_img"><img src="img/new-icons/house.svg" alt="cars & vehcles" title="services to be paid for your land rates"></div>
                                                                    <div  title="county parking services" class="service-link-title pb-4"> <span class="name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">land rates</font></font> </span> <span class="arrow"><i></i></span></div>
                                                                    <ul class="dropdown-menu toggle_menu text-capitalize">
                                                                        <!--									revenue stream service-->
                                                                        <li class="menu-item "> <a href="land-rates.html" title="pay for your land rates" style="white-space: normal;" > <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">pay land rates</font> </font> </span> </a> </li>
                                                                        <!--									revenue stream service-->
                                                                        <!--									revenue stream service-->
                                                                        <li class="menu-item"> <a href="#" class="get-a-receipt" title="print a transaction's receipt" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">print receipt</font> </font> </span> </a> </li>
                                                                        <!--									revenue stream service-->
                                                                    </ul>
                                                                </li>
                                                                <li class="dropdown-submenu  has_img">
                                                                    <div class="menu_img"><img src="img/new-icons/key.svg" alt="cars & vehcles" title="services to be paid for your land rates"></div>
                                                                    <div  title="pay for bills" class="service-link-title pb-4"> <span class="name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">rents</font></font> </span> <span class="arrow"><i></i></span></div>
                                                                    <ul class="dropdown-menu toggle_menu text-capitalize">
                                                                        <!--									revenue stream service-->
                                                                        <li class="menu-item "> <a href="house-rents.html" title="pay for your house rents" style="white-space: normal;" > <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">house rents</font> </font> </span> </a> </li>
                                                                        <!--									revenue stream service-->
                                                                        <!--									revenue stream service-->
                                                                        <li class="menu-item"> <a href=market-rents.html title="pay for your market stalls rental fees" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">market stalls</font> </font> </span> </a> </li>
                                                                        <!--									revenue stream service-->
                                                                        <li class="menu-item"> <a href="#" class="get-a-receipt" title="search for receipt" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">receipt</font> </font> </span> </a> </li>
                                                                        <!--									revenue stream service-->

                                                                    </ul>
                                                                </li>
                                                                <li class="dropdown-submenu  has_img">
                                                                    <div class="menu_img"><img src="img/new-icons/parking.svg" alt="cars & vehcles" title="services to be paid for your land rates"></div>
                                                                    <div  title="county rental fees" class="service-link-title pb-4"> <span class="name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">parking fees</font></font> </span> <span class="arrow"><i></i></span></div>
                                                                    <ul class="dropdown-menu toggle_menu text-capitalize">
                                                                        <!--									revenue stream service-->
                                                                        <li class="menu-item "> <a href="daily-parking.html"  title="pay for your daily parking fees" style="white-space: normal;" > <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">daily parking</font> </font> </span> </a> </li>
                                                                        <!--									revenue stream service-->
                                                                        <!--									revenue stream service-->
                                                                        <li class="menu-item "> <a href="seasonal-parking.html" title="pay parking for a specified period of time" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">seasonal parking</font> </font> </span> </a> </li>
                                                                        <!--									revenue stream service-->
                                                                        <li class="menu-item d-none"> <a href="#" title="pay monthly parking for your sacco fleet" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">sacco parking</font> </font> </span> </a> </li>

                                                                        <!--									revenue stream service-->
                                                                        <li class="menu-item"> <a href="offstreet-parking.html" title="offstreet parking fees" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">offstreet parking</font> </font> </span> </a> </li>
                                                                        <!--									revenue stream service-->
                                                                        <!--									revenue stream service-->
                                                                        <li class="menu-item"> <a href="parking-penalty.html" title="pay for parking penalties" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">penalties</font> </font> </span> </a> </li>
                                                                        <!--									revenue stream service-->
                                                                        <!--									revenue stream service-->
                                                                        <li class="menu-item"> <a href="#" class="get-a-receipt" title="phave a look at some of your parking receipts" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">receipts</font> </font> </span> </a> </li>
                                                                        <!--									revenue stream service-->
                                                                        <!--									revenue stream service-->

                                                                    </ul>
                                                                </li>
                                                                <li class="dropdown-submenu  has_img">
                                                                    <div class="menu_img"><img src="img/new-icons/receipt.svg" alt="cars & vehcles" title="services to be paid for your land rates"></div>
                                                                    <div  title="county parking services" class="service-link-title pb-4"> <span class="name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">county bills</font></font> </span> <span class="arrow"><i></i></span></div>
                                                                    <ul class="dropdown-menu toggle_menu text-capitalize">
                                                                        <!--									revenue stream service-->
                                                                        <li class="menu-item "> <a href="create-bill.html" title="pay for your land rates" style="white-space: normal;" > <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">create food handlers bill</font> </font> </span> </a> </li>
                                                                        <!--									revenue stream service-->

                                                                        <!--									revenue stream service-->
                                                                        <li class="menu-item "> <a href="pay-bill.html" title="pay for your land rates" style="white-space: normal;" > <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">pay for a bill</font> </font> </span> </a> </li>
                                                                        <!--									revenue stream service-->
                                                                        <!--									revenue stream service-->
                                                                        <li class="menu-item"> <a href="#" title="print a transaction's receipt" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">print bill</font> </font> </span> </a> </li>
                                                                        <!--									revenue stream service-->

                                                                    </ul>
                                                                </li>
                                                                <li class="dropdown-submenu  has_img">
                                                                    <div class="menu_img"><img src="img/new-icons/brickwall.svg" alt="cars & vehcles" title="services to be paid for your land rates"></div>
                                                                    <div  title="county construction permits" class="service-link-title pb-4"> <span class="name"><font style="vertical-align: inherit;" class="text-lowercase"><font style="vertical-align: inherit;">e-Construction</font></font> </span> <span class="arrow"><i></i></span></div>
                                                                    <ul class="dropdown-menu toggle_menu text-capitalize">
                                                                        <!--									revenue stream service-->
                                                                        <li class="menu-item "> <a href="#" title="pay for a construction permit" style="white-space: normal;" > <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">pay permit</font> </font> </span> </a> </li>
                                                                        <!--									revenue stream service-->
                                                                        <!--									revenue stream service-->
                                                                        <li class="menu-item"> <a href="#" title="print an e-costruction receipt" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">print receipt</font> </font> </span> </a> </li>
                                                                        <!--									revenue stream service-->

                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                        </div></td>
                                                        <td class="menu-item dropdown" style="visibility: visible;"><div class="wrap"> <a class="dropdown-toggle" href="#/">
                                                            <div><font style="vertical-align: inherit;"><font style="vertical-align: inherit;"> Help </font></font>
                                                                <div class="line-wrapper"><span class="line"></span></div>
                                                            </div>
                                                        </a> <span class="tail"></span>
                                                            <ul class="dropdown-menu text-capitalize">
                                                                <li class="  "> <a href="#" title="FAQs"><span class="name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">FAQs</font></font></span></a> </li>
                                                                <li class="  "> <a href="/help/delivery/" title="Tearms Of Use"><span class="name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">guidlines</font></font></span></a> </li>
                                                            </ul>
                                                        </div></td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="line-row visible-xs"></div>
            </div>
        </header>
    </div>
    <div class="jqmOverlay search"></div>
    <div id="headerfixed">
        <div class="maxwidth-theme container">
            <div class="logo-row v2 row margin0 menu-row">
                <div class="inner-table-block nopadding logo-block">
                    <div class="logo"> <a href="javascript: void(0);" @click="gotTo('/')"><img src="img/logo/county_logo.png" alt="Kisumu ePayment Platform" title="Epayments" height="88px"></a> </div>
                </div>
                <div class="inner-table-block menu-block" style="width: 70%;">
                    <div class="navs table-menu js-nav opacity1" >
                        <nav class="mega-menu sliced ovisible initied">
                            <div class="table-menu">
                                <table>
                                    <tbody>
                                    <tr>
                                        <td class="menu-item dropdown catalog wide_menu" style="visibility: visible;"><div class="wrap"> <a class="dropdown-toggle" href="#">
                                            <div><font style="vertical-align: inherit;"><font style="vertical-align: inherit;"> Services</font></font>
                                                <div class="line-wrapper"><span class="line"></span></div>
                                            </div>
                                        </a> <span class="tail"></span>
                                            <ul class="dropdown-menu text-capitalize">
                                                <li class="dropdown-submenu  has_img">
                                                    <div class="menu_img"><img src="img/new-icons/case.svg" alt="cars & vehcles" title="cars & vehcles"></div>
                                                    <div  title="county parking services" class="service-link-title pb-4"> <span class="name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Single Business Permits</font></font> </span> <span class="arrow"><i></i></span></div>
                                                    <ul class="dropdown-menu toggle_menu text-capitalize">
                                                        <!--									revenue stream service-->
                                                        <li class="menu-item "> <a href="#" title="renew your business permits" style="white-space: normal;" > <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">renew SBP</font> </font> </span> </a> </li>
                                                        <!--									revenue stream service-->
                                                        <!--									revenue stream service-->
                                                        <li class="menu-item"> <a href="#" title="pay or check for penalties" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">register new business</font> </font> </span> </a> </li>
                                                        <!--									revenue stream service-->

                                                        <!--									revenue stream service-->
                                                        <li class="menu-item"> <a href="#" title="print a permit" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">print permit</font> </font> </span> </a> </li>
                                                        <!--									revenue stream service-->

                                                        <!--									revenue stream service-->
                                                        <li class="menu-item"> <a href="#" title="print receipt for specific permit" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">print receipt</font> </font> </span> </a> </li>
                                                        <!--									revenue stream service-->
                                                    </ul>
                                                </li>
                                                <li class="dropdown-submenu  has_img">
                                                    <div class="menu_img"><img src="img/new-icons/house.svg" alt="cars & vehcles" title="services to be paid for your land rates"></div>
                                                    <div  title="county parking services" class="service-link-title pb-4"> <span class="name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">land rates</font></font> </span> <span class="arrow"><i></i></span></div>
                                                    <ul class="dropdown-menu toggle_menu text-capitalize">
                                                        <!--									revenue stream service-->
                                                        <li class="menu-item "> <a href="land-rates.html" title="pay for your land rates" style="white-space: normal;" > <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">pay land rates</font> </font> </span> </a> </li>
                                                        <!--									revenue stream service-->
                                                        <!--									revenue stream service-->
                                                        <li class="menu-item"> <a href="#" class="get-a-receipt" title="print a transaction's receipt" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">print receipt</font> </font> </span> </a> </li>
                                                        <!--									revenue stream service-->
                                                    </ul>
                                                </li>
                                                <li class="dropdown-submenu  has_img">
                                                    <div class="menu_img"><img src="img/new-icons/key.svg" alt="cars & vehcles" title="services to be paid for your land rates"></div>
                                                    <div  title="pay for bills" class="service-link-title pb-4"> <span class="name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">rents</font></font> </span> <span class="arrow"><i></i></span></div>
                                                    <ul class="dropdown-menu toggle_menu text-capitalize">
                                                        <!--									revenue stream service-->
                                                        <li class="menu-item "> <a href="house-rents.html" title="pay for your house rents" style="white-space: normal;" > <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">house rents</font> </font> </span> </a> </li>
                                                        <!--									revenue stream service-->
                                                        <!--									revenue stream service-->
                                                        <li class="menu-item"> <a href="market-rents.html" title="pay for your market stalls rental fees" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">market stalls</font> </font> </span> </a> </li>
                                                        <!--									revenue stream service-->
                                                        <li class="menu-item"> <a href="#" class="get-a-receipt" title="search for receipt" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">receipt</font> </font> </span> </a> </li>
                                                        <!--									revenue stream service-->

                                                    </ul>
                                                </li>
                                                <li class="dropdown-submenu  has_img">
                                                    <div class="menu_img"><img src="img/new-icons/parking.svg" alt="cars & vehcles" title="services to be paid for your land rates"></div>
                                                    <div  title="county rental fees" class="service-link-title pb-4"> <span class="name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">parking fees</font></font> </span> <span class="arrow"><i></i></span></div>
                                                    <ul class="dropdown-menu toggle_menu text-capitalize">
                                                        <!--									revenue stream service-->
                                                        <li class="menu-item "> <a href="daily-parking.html" title="pay for your daily parking fees" style="white-space: normal;" > <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">daily parking</font> </font> </span> </a> </li>
                                                        <!--									revenue stream service-->
                                                        <!--									revenue stream service-->
                                                        <li class="menu-item"> <a href="seasonal-parking.html" title="pay parking for a specified period of time" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">seasonal parking</font> </font> </span> </a> </li>
                                                        <!--									revenue stream service-->
                                                        <li class="menu-item"> <a href="#" title="pay monthly parking for your sacco fleet" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">sacco parking</font> </font> </span> </a> </li>

                                                        <!--									revenue stream service-->
                                                        <li class="menu-item"> <a href="offstreet-parking.html" title="offstreet parking fees" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">offstreet parking</font> </font> </span> </a> </li>
                                                        <!--									revenue stream service-->
                                                        <!--									revenue stream service-->
                                                        <li class="menu-item"> <a href="parking-penalty.html" title="pay for parking penalties" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">penalties</font> </font> </span> </a> </li>
                                                        <!--									revenue stream service-->
                                                        <!--									revenue stream service-->
                                                        <li class="menu-item"> <a href="#" class="get-a-receipt" title="phave a look at some of your parking receipts" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">receipts</font> </font> </span> </a> </li>
                                                        <!--									revenue stream service-->
                                                        <!--									revenue stream service-->

                                                    </ul>
                                                </li>
                                                <li class="dropdown-submenu  has_img">
                                                    <div class="menu_img"><img src="img/new-icons/receipt.svg" alt="cars & vehcles" title="services to be paid for your land rates"></div>
                                                    <div  title="county parking services" class="service-link-title pb-4"> <span class="name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">county bills</font></font> </span> <span class="arrow"><i></i></span></div>
                                                    <ul class="dropdown-menu toggle_menu text-capitalize">
                                                        <!--									revenue stream service-->
                                                        <li class="menu-item "> <a href="pay-bill.html" title="pay for your land rates" style="white-space: normal;" > <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">pay for a bill</font> </font> </span> </a> </li>
                                                        <!--									revenue stream service-->

                                                        <!--									revenue stream service-->
                                                        <li class="menu-item "> <a href="create-bill.html" title="pay for your land rates" style="white-space: normal;" > <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">create food handlers bill</font> </font> </span> </a> </li>
                                                        <!--									revenue stream service-->

                                                        <!--									revenue stream service-->
                                                        <li class="menu-item"> <a href="#" title="print a transaction's receipt" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">print bill</font> </font> </span> </a> </li>
                                                        <!--									revenue stream service-->

                                                    </ul>
                                                </li>
                                                <li class="dropdown-submenu  has_img">
                                                    <div class="menu_img"><img src="img/new-icons/brickwall.svg" alt="cars & vehcles" title="services to be paid for your land rates"></div>
                                                    <div  title="county construction permits" class="service-link-title pb-4"> <span class="name"><font style="vertical-align: inherit;" class="text-lowercase"><font style="vertical-align: inherit;">e-Construction</font></font> </span> <span class="arrow"><i></i></span></div>
                                                    <ul class="dropdown-menu toggle_menu text-capitalize">
                                                        <!--									revenue stream service-->
                                                        <li class="menu-item "> <a href="#" title="pay for a construction permit" style="white-space: normal;" > <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">pay permit</font> </font> </span> </a> </li>
                                                        <!--									revenue stream service-->
                                                        <!--									revenue stream service-->
                                                        <li class="menu-item"> <a href="#" title="print an e-costruction receipt" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">print receipt</font> </font> </span> </a> </li>
                                                        <!--									revenue stream service-->

                                                    </ul>
                                                </li>
                                            </ul>
                                        </div></td>
                                        <td class="menu-item dropdown" style="visibility: visible;"><div class="wrap"> <a class="dropdown-toggle" href="#">
                                            <div><font style="vertical-align: inherit;"><font style="vertical-align: inherit;"> Help</font></font>
                                                <div class="line-wrapper"><span class="line"></span></div>
                                            </div>
                                        </a> <span class="tail"></span>
                                            <ul class="dropdown-menu text-capitalize">
                                                <li class="  "> <a href="#" title="FAQs"><span class="name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">FAQs</font></font></span></a> </li>
                                                <li class="  "> <a href="/help/delivery/" title="Tearms Of Use"><span class="name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">guidlines</font></font></span></a> </li>
                                            </ul>
                                        </div></td>
                                        <td class="menu-item dropdown js-dropdown nosave" style="display: none; visibility: visible;"><div class="wrap"> <a class="dropdown-toggle more-items" href="#"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yet</font></font></span> </a> <span class="tail"></span>
                                            <ul class="dropdown-menu">
                                            </ul>
                                        </div></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </nav>
                    </div>
                </div>

                <div class="inner-table-block nopadding small-block">
                    <div class="wrap_icon wrap_cabinet">
                        <div id="bxdynamic_header-auth-block2_start" style="display:none"></div>
                        <!-- noindex --><a rel="nofollow" title="log in" class="personal-link dark-color animate-load" data-event="jqm" data-param-type="auth" data-param-backurl="/" data-name="auth" href="#"><i class="svg inline big svg-inline-cabinet" aria-hidden="true" title="log in">
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
                           <!-- <defs>
                                <style>
                                    .loccls-1 {
                                        fill: #222;
                                        fill-rule: evenodd;
                                    }
                                </style>
                            </defs>-->
                            <path data-name="Rounded Rectangle 110" class="loccls-1" d="M1433,132h-15a3,3,0,0,1-3-3v-7a3,3,0,0,1,3-3h1v-2a6,6,0,0,1,6-6h1a6,6,0,0,1,6,6v2h1a3,3,0,0,1,3,3v7A3,3,0,0,1,1433,132Zm-3-15a4,4,0,0,0-4-4h-1a4,4,0,0,0-4,4v2h9v-2Zm4,5a1,1,0,0,0-1-1h-15a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h15a1,1,0,0,0,1-1v-7Zm-8,3.9v1.6a0.5,0.5,0,1,1-1,0v-1.6A1.5,1.5,0,1,1,1426,125.9Z" transform="translate(-1415 -111)"></path>
                        </svg>
                    </i></a><!-- /noindex -->
                        <div id="bxdynamic_header-auth-block2_end" style="display:none"></div>
                    </div>
                </div>
                <div class="inner-table-block small-block nopadding inline-search-show" data-type_search="fixed">
                    <div class="search-block top-btn"><i class="svg svg-search lg"></i></div>
                </div>
            </div>
        </div>
    </div>
    <div id="mobileheader" class="visible-xs visible-sm">
        <div class="mobileheader-v2">
            <form>
                <!--			mobile search bar-->
                <input type="text" placeholder="search" id="mobile-search-box" class="search-expand">
                <input type="submit">
                <!--			mobile search bar-->
                <!--		mobile search bar quick options -->
                <div class="search-options-panel d-none">
                    <!--				search option item	-->
                    <span class="search-option"> <img src="images/97d3a6a67f5daf82e471d9a4bc44726b.png" width="30px" height="30px"/>
        <p class="option-item"><b>Samsung Galaxy S8 </b><i>Phones & Accessories</i></p>
        </span>
                    <!--				search option item-->
                    <!--				search option item	-->
                    <span class="search-option"> <img src="images/97d3a6a67f5daf82e471d9a4bc44726b.png" width="30px" height="30px"/>
        <p class="option-item"><b>Nikon D40</b><i>Electronics & Gadgets</i></p>
        </span>
                    <!--				search option item-->
                    <!--				search option item	-->
                    <span class="search-option"> <img src="images/97d3a6a67f5daf82e471d9a4bc44726b.png" width="30px" height="30px"/>
        <p class="option-item"><b>Samsung TV 45" </b><i>Electronics & Gadgets</i></p>
        </span>
                    <!--				search option item-->
                    <!--				search option item	-->
                    <span class="search-option"> <img src="images/97d3a6a67f5daf82e471d9a4bc44726b.png" width="30px" height="30px"/>
        <p class="option-item"><b>BMW X6</b><i>Cars & Vehcles</i></p>
        </span>
                    <!--				search option item-->
                    <!--				search option item	-->
                    <span class="search-option"> <img src="images/97d3a6a67f5daf82e471d9a4bc44726b.png" width="30px" height="30px"/>
        <p class="option-item"><b>Canono T3200</b><i>Electronics & Gadgets</i></p>
        </span>
                    <!--				search option item-->
                </div>
                <!--			mobile search bar quick options -->
            </form>
            <i class="txt-box-back text-black"><i data-feather="arrow-left" class="text-black"></i></i> <i class="txt-box-clear"><i data-feather="x" class="text-black"></i></i>
            <div class="burger pull-left"> <i class="svg inline  svg-inline-burger dark svg-inline-cabinet" aria-hidden="true" data-feather="menu"></i> <i class="svg inline  svg-inline-close dark" aria-hidden="true" data-feather="x">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                   <!-- <defs>
                        <style>
                            .cccls-1 {
                                fill: #222;
                                fill-rule: evenodd;
                            }
                        </style>
                    </defs>-->
                    <path data-name="Rounded Rectangle 114 copy 3" class="cccls-1" d="M334.411,138l6.3,6.3a1,1,0,0,1,0,1.414,0.992,0.992,0,0,1-1.408,0l-6.3-6.306-6.3,6.306a1,1,0,0,1-1.409-1.414l6.3-6.3-6.293-6.3a1,1,0,0,1,1.409-1.414l6.3,6.3,6.3-6.3A1,1,0,0,1,340.7,131.7Z" transform="translate(-325 -130)"></path>
                </svg>
            </i> </div>
            <div class="title-block col-sm-6 col-xs-5 pull-left"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Nairobi <small>e-Payments</small></font></font></div>
            <div class="right-icons pull-right">
                <div class="pull-right">
                    <div class="wrap_icon">
                        <button class="top-btn mobile-search-invoke twosmallfont"> <i class="svg inline big text-white" aria-hidden="true" data-feather="search"></i> </button>
                    </div>
                </div>
                <div class="pull-right">
                    <div class="wrap_icon wrap_cabinet">
                        <div id="bxdynamic_header-auth-block3_start" style="display:none"></div>
                        <!-- noindex --><a rel="nofollow" title="log-in" class="personal-link dark-color animate-load" data-event="jqm" data-param-type="auth" data-param-backurl="/" data-name="auth" href="#"> <i class="svg inline big white svg-inline-cabinet text-white" aria-hidden="true" title="log-in" data-feather="power"></i> </a><!-- /noindex -->
                        <div id="bxdynamic_header-auth-block3_end" style="display:none"></div>
                    </div>
                </div>
            </div>
        </div>

        <div id="mobilemenu-overlay"></div>
        <div id="mobilemenu-overlay"></div>
    </div>
    <div id="mobilemenu" class="leftside">
        <div class="mobilemenu-v1 scroller">
            <div class="wrap">
                <div class="prof-container">
                    <div class="prof-bg-img"> <img src="img/bg/nairobibg.jpg"/> </div>
                    <div class="user-dp"><img src="img/user/default-user.png"></div>
                    <p class="mt-2">User Name</p>
                    <p class="email-address">Useremail@email.com</p>
                    <p class="log-out" title="logout"><i class="icon-off"><i data-feather="power"></i></i></p>
                </div>
                <div class="menu top">
                    <ul class="top text-capitalize">
                        <li>
                            <!--my profile-->
                            <a class="dark-color parent" title="How to buy"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">my account</font></font></span> <span class="arrow"><i class="text-black" data-feather="chevron-right"></i></span> </a>
                            <ul class="dropdown">
                                <li class="menu_back"><a  class="dark-color  d-flex align-items-center" rel="nofollow"><i class="text-black" data-feather="arrow-left"></i><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Go back</font></font></a></li>
                                <li class="menu_title"><a href="#"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">My Account</font></font></a></li>
                                <!--my profile links-->
                                <li> <a class="dark-color" href="#" title="check out your profile details"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">my profile</font></font></span> </a> </li>
                                <!--my profile links-->
                                <li> <a class="dark-color" href="#" title="My ads"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">transactions history</font><span class="count-item d-none">0</span></font></span> </a> </li>
                                <!--my profile links-->
                                <li> <a class="dark-color" href="#" title="Your registered assets"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">My assets</font><span class="count-item">0</span></font></span> </a> </li>
                                <!--my profile links-->
                                <li> <a class="dark-color" href="#" title="your created bills"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">my bills</font><span class="count-item">0</span></font></span> </a> </li>
                                <!--my profile links-->
                                <li> <a class="dark-color" href="#" title="change your account's password"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">change password</font></font></span> </a> </li>
                                <!--my profile links-->
                                <li> <a class="dark-color" href="#" title="log out my account"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">log out</font><span class="count-item d-none">0</span></font></span> </a> </li>
                            </ul>
                        </li>
                        <li class="selected"> <a class="dark-color parent"  title="Catalog"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">single business permit</font></font></span> <span class="arrow"><i class="text-black" data-feather="chevron-right"></i></span> </a>
                            <ul class="dropdown">
                                <li class="menu_back"><a  class="dark-color  d-flex align-items-center" rel="nofollow"><i class="text-black" data-feather="arrow-left"></i><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Go back</font></font></a></li>
                                <li class="menu_title"><a href="#"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">single business permit</font></font></a></li>
                                <li> <a class="dark-color" href="#" title="renew permit for an existing business"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">renew permit</font></font></span></a> </li>
                                <!--category-->
                                <li> <a class="dark-color" href="#" title="register a new business"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">register new business</font></font></span></a> </li>
                                <!--category-->
                            </ul>
                        </li>
                        <li> <a class="dark-color parent" title="How to buy"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">land rates</font></font></span> <span class="arrow"><i class="text-black" data-feather="chevron-right"></i></span> </a>
                            <ul class="dropdown">
                                <li class="menu_back"><a  class="dark-color  d-flex align-items-center" rel="nofollow"><i class="text-black" data-feather="arrow-left"></i><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Go back</font></font></a></li>
                                <!--				land rate sub menu-->
                                <li class="menu_title"><a href="#"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">land rates</font></font></a></li>
                                <!--				land rate sub menu-->
                                <li> <a class="dark-color" href="#" title="pay for your land rates"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">pay land rate</font></font></span> </a> </li>
                                <!--				land rate sub menu-->
                                <li> <a class="dark-color" href="#" title="print a land rate receipt"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">print land rate</font></font></span> </a> </li>
                                <!--				land rate sub menu-->
                            </ul>
                        </li>
                        <li> <a class="dark-color parent" title="county rental fees"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">rents</font></font></span> <span class="arrow"><i class="text-black" data-feather="chevron-right"></i></span> </a>
                            <ul class="dropdown">
                                <li class="menu_back"><a  class="dark-color  d-flex align-items-center" rel="nofollow"><i class="text-black" data-feather="arrow-left"></i><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Go back</font></font></a></li>
                                <!--				rents ssub menu-->
                                <li class="menu_title"><a href="#"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">rents</font></font></a></li>

                                <!--				rents ssub menu-->
                                <li> <a class="dark-color" href="#" title="pay for house rents"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">house rents</font></font></span> </a> </li>
                                <!--				rents ssub menu-->
                                <li> <a class="dark-color" href="#" title="pay for stall rents"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">stall rents</font></font></span> </a> </li>
                                <!--				rents ssub menu-->
                                <li> <a class="dark-color" href="#" title="print a receipt"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">receipts</font></font></span> </a> </li>
                            </ul>
                        </li>
                        <li> <a class="dark-color parent" title="Company"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">parking</font></font></span> <span class="arrow"><i class="text-black" data-feather="chevron-right"></i></span> </a>
                            <ul class="dropdown">
                                <li class="menu_back"><a  class="dark-color  d-flex align-items-center" rel="nofollow"><i class="text-black" data-feather="arrow-left"></i><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Go back</font></font></a></li>
                                <!--				rents ssub menu-->
                                <li class="menu_title"><a href="#"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">parking</font></font></a></li>
                                <!--				rents ssub menu-->
                                <li> <a class="dark-color" href="#" title="pay for your daily parking"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">daily parking</font></font></span> </a> </li>
                                <!--				rents ssub menu-->
                                <li> <a class="dark-color" href="#" title="parking fee for specified period of time"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">seasonal parking</font></font></span> </a> </li>

                                <!--				rents ssub menu-->
                                <li> <a class="dark-color" href="#" title="parking for a fleet of cars"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">sacco parking</font></font></span> </a> </li>

                                <!--				rents ssub menu-->
                                <li> <a class="dark-color" href="#" title="offstreet parking"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">offstreet parking</font></font></span> </a> </li>

                                <!--				rents ssub menu-->
                                <li> <a class="dark-color" href="#" title="parking penalties"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">penalties</font></font></span> </a> </li>

                                <!--				rents ssub menu-->
                                <li> <a class="dark-color" href="#" title="transactions receipts"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">parking receipts</font></font></span> </a> </li>
                            </ul>
                        </li>
                        <li> <a class="dark-color parent" title="county bills"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">county bills</font></font></span> <span class="arrow"><i class="text-black" data-feather="chevron-right"></i></span> </a>
                            <ul class="dropdown">
                                <li class="menu_back"><a  class="dark-color  d-flex align-items-center" rel="nofollow"><i class="text-black" data-feather="arrow-left"></i><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Go back</font></font></a></li>
                                <!--				bills ssub menu-->
                                <li class="menu_title"><a href="#"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">county bills</font></font></a></li>

                                <!--				bills ssub menu-->
                                <li> <a class="dark-color" href="#" title="pay for an already created county bill"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">pay for a bill</font></font></span> </a> </li>
                                <!--				bills  ssub menu-->
                                <li> <a class="dark-color" href="#" title="print a bill"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">print a bill</font></font></span> </a> </li>
                                <!--				bills ssub menu-->

                            </ul>
                        </li>
                        <li> <a class="dark-color parent" title="construction permits"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">e-Construction</font></font></span> <span class="arrow"><i class="text-black" data-feather="chevron-right"></i></span> </a>
                            <ul class="dropdown">
                                <li class="menu_back"><a  class="dark-color  d-flex align-items-center" rel="nofollow"><i class="text-black" data-feather="arrow-left"></i><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Go back</font></font></a></li>
                                <!--				bills ssub menu-->
                                <li class="menu_title"><a href="#"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">e-Construction</font></font></a></li>

                                <!--				bills ssub menu-->
                                <li> <a class="dark-color" href="#" title="pay for permit"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">pay for permit</font></font></span> </a> </li>
                                <!--				bills  ssub menu-->
                                <li> <a class="dark-color" href="#" title="print transactions document"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">print permit</font></font></span> </a> </li>
                                <!--				bills ssub menu-->

                            </ul>
                        </li>
                    </ul>
                    <!--my profile-->

                </div>
            </div>
        </div>
    </div>
    <div id="mobilemenu-overlay"></div>
    <!--	search receipt pop up-->
    <section class="account-pop-up h-100vh w-100 justify-content-center align-items-center animated d-none" id="search-receipt">
        <div class="container p-lg-5 p-md-0">
            <div class="row p-5">
                <div class="receipt-search-container m-5 d-flex flex-column-md animated" id="get-receipt-container">
                    <div class="col-lg-6 receipt-img-container">
                        <div class="col-lg-8 col-md-12 position-relative p-5">
                            <h2 class="mb-4 text-capitalize text-white">print a receipt for a transaction</h2>
                            <p class="font-14 mb-3 text-capitalize">Make sure all inputs are correct. The process has been made this way to help protect personal data</p>
                        </div>
                    </div>
                    <div class="col-lg-6 p-5 position-relative"> <span class="close-receipt-form position-absolute"> <i data-feather="x"></i></span>
                        <form class="transaction-info">
                            <div class="">
                                <h4 class="mb-5 pb-2 text-capitalize">correctly fill in the form bellow to continue</h4>
                            </div>
                            <div class="row m-0">
                                <div  class="form-group col-sm-12 pl-md-0">
                                    <label for="sel1"><small>What was the transacton type?</small></label>
                                    <select class="form-control mb-3" id="sel1" required>
                                        <option>Parking</option>
                                        <option>Business</option>
                                        <option class="text-capitalize">County bills</option>
                                        <option class="text-capitalize">land rates</option>
                                        <option class="text-capitalize">rents</option>
                                        <option class="text-capitalize">e-Construction</option>
                                        <option></option>
                                    </select>
                                </div>
                            </div>
                            <div class="row radio-container m-0">
                                <div class="col-12 pl-md-0 mb-3">
                                    <div class="form-check-inline">
                                        <label class="form-check-label">M-Pesa
                                            <input type="radio" class="form-check-input" name="method" required>
                                            <span class="checkmark"></span> </label>
                                    </div>
                                    <div class="form-check-inline">
                                        <label class="form-check-label">NBK
                                            <input type="radio" class="form-check-input" name="method" required>
                                            <span class="checkmark"></span> </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row m-0">
                                <div class="form-group col-sm-12 pl-md-0 mb-3">
                                    <label for="exampleInputEmail1" class="text-capitalize"><small>what was the refference number for the transaction?</small></label>
                                    <input type="text" class="form-control" id="fname" placeholder="eg M-Pesa ref number" required>
                                </div>
                                <div class="form-group col-sm-12 pl-md-0 mb-3">
                                    <label for="identifier" class="text-capitalize"><small>Unique identifire for transaction eg Number plate, bill number, Business ID etc</small></label>
                                    <input type="text" class="form-control" id="identifier" placeholder="identifier" required>
                                </div>
                                <button type="submit" class="btn btn-primary text-capitalize text-white font-14 submit-btn">get receipt</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--form section-->
    <!--	pay confirmation pop up-->
    <section v-if="payConfirmationPop" class="account-pop-up h-100vh w-100 justify-content-center align-items-center animated" id="pay-confirmation-pop">
        <div class="container p-md-5 p-sm-0">
            <div class="row p-5 d-flex justify-content-center">
                <div class="col-sm-12 col-md-6 col-lg-5 payment-pop-container m-5 m-sm-3 d-flex flex-column-md animated p-0">

                    <div class="col-12 position-relative p-0">
                        <span class="close-receipt-form position-absolute z-index-1 d-none transactions-actions animated text-white" id="close-pricepop"> <i data-feather="x"></i></span>

                        <div class="">
                            <div class="show-amount-loader">
                                <center class="p-5 text-white">
                                    <div v-if="loadingPayment" class="lds-roller animated"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                    <div v-if="paymentReceived" class="confirmed-pay animated"><img src="img/icons/transactions-icons/check.svg"></div>
                                    <p v-if="!paymentReceived" class="text-center text-white m-0 p-0 mb-3  payment-status">pending {{feesAndCharge.feeDescription}} payment</p>
                                    <h2 class="mb-5 pb-5 text-capitalize text-white"><sup class="font-14 text-uppercase">Kes</sup>{{feesAndCharge.unitFeeAmount}}</h2>
                                </center>
                            </div>
                            <div class="col-12 p-lg-5 p-sm-3 ">
                                <div v-if="!paymentReceived" class="col-12 p-0 transacton-instructions">
                                    <h5><strong class="text-capitalize pb-3">payment procedure</strong></h5>
                                    <p class="font-12">follow the following payment procedure in order to complete the payment</p>

                                    <hr>
                                    <ul type="1" class="font-14 pb-5">
                                        <li>1.Check on a <strong>payment pop </strong>up on your phone.</li>
                                        <li>2.Input your <strong>M-pesa pin</strong> and click OK.</li>
                                        <li>3.An <strong>MPESA</strong> confimation SMS will be sent to you.</li>
                                        <li>4.Wait for upto <strong>45 secs</strong> as we process your transaction</li>
                                    </ul>
                                </div>

                                <div v-if="paymentReceived" class="col-12 p-0 pb-3 transactions-actions animated mt-5">
                                    <h5><strong class="text-capitalize pb-3">Thank You {{transaction.names}}!</strong></h5>
                                    <p class="font-14 text-capitalize m-0">Payment was successfully received. <strong>{{transaction.transaction_code}}</strong></p>
                                    <p class="font-14">you can close the pop up or perform the actions bellow</p>
                                    <a @click="printReceipt()" href="javascript: void(0);" class="btn btn-primary px-5 py-3 text-white text-uppercase font-12 font-sm-10 px-sm-4">Print Receipts</a>
                                    <!--<a href="#" class="btn btn-secondary px-5 py-3 text-black text-uppercase font-12 font-sm-10 px-sm-4">add to assets</a>-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--	pay confirmation pop up-->
    <section id="service-form-section" class="parallax-section">
        <div class="container">
            <div class="row p-5 ">
                <div class="service-form-container  d-flex flex-column-md animated col-12 p-0">
                    <div class="col-lg-7 service-form-img-container" id="parking-img">
                        <div class="col-lg-8 col-md-12 position-relative p-5">
                            <h2 class="mb-4 text-capitalize text-white">NCCC Daily Parking</h2>
                            <p class="font-14 mb-3 text-capitalize">fill in and remember to double check details to avoid any errors.</p>
                        </div>
                    </div>
                    <div class="col-lg-5 p-5 position-relative transactions-form-container d-flex">
                        <div class="col-12 p-0 the-transaction-form animated">
                            <div class="">
                                <p class="mb-5 pb-2 text-capitalize"><strong>correctly fill in the form bellow to continue</strong></p>
                            </div>
                            <form class="transaction-form">

                                <div class="form-group col-sm-12 col-md-12 p-0">
                                    <label for="zone" class="text-capitalize">select a parking Duration</label>

                                    <select class="form-control bg-white" data-live-search="true" v-model="incomeType"
                                            title="Select Sub County">

                                        <option :key="index" :value="item"
                                                v-for="(item, index) in incomeTypes">
                                            {{ item.incomeTypeDescription }}
                                        </option>
                                    </select>
                                </div>

                                <div class="form-group col-sm-12 col-md-12 p-0">
                                    <label for="zone" class="text-capitalize">Select Vehicle Type</label>
                                    <select class="form-control bg-white" data-live-search="true" v-model="feesAndCharge"
                                            title="Select Sub County">

                                        <option :key="index" :value="item"
                                                v-for="(item, index) in feesAndCharges">
                                            {{ item.feeDescription }}
                                        </option>
                                    </select>
                                </div>

                                <div class="col-12 p-4 center danger-light-bg lighter-txt price-container position-relative">
                                    <span class="close-price-container position-absolute"> <i data-feather="x"></i></span>
                                    <p class="text-uppercase m-0 font-14"><strong>sub total</strong></p>
                                    <p class="text-uppercase m-0 font-12">kes {{feesAndCharge.unitFeeAmount}}</p>
                                </div>
                                <div class="form-group col-sm-12 col-md-12 p-0 my-3">
                                    <p class="text-capitalize">payment options</p>
                                    <div class="col-12 p-0 pay-container">
                                        <label for="mpesa" class=" gray-bg w-100 row d-flex justify-content-center align-items-center align-content-center m-0 pay-method">
                                            <div class="col-lg-1 col-md-2 pr-0 radio-side">
                                                <div class="radio-container col-12 p-0 w-100 h-100">
                                                    <input type="radio" class="form-check-input" name="method" required="" id="mpesa" checked>
                                                    <span class="checkmark"></span> </div>
                                            </div>
                                            <div class="col-md-10 col-lg-11 py-4">
                                                <p class="m-0 font-12 text-capitalize"><strong> Mpesa</strong></p>
                                                <p class="font-10px m-0">Pay instantly via mPesa. Enter your <strong>mpesa phone number</strong> and a payment request will be sent automatically to your phone</p>
                                            </div>
                                            <span class="checkmark"></span>
                                        </label>
                                        <label for="airtel" class=" w-100 row d-flex justify-content-center align-items-center align-content-center m-0 pay-method">
                                            <div class="col-lg-1 col-md-2 pr-0 radio-side">
                                                <div class="radio-container col-12 p-0 w-100 h-100">
                                                    <input type="radio" class="form-check-input" name="method" required="" id="airtel">
                                                    <span class="checkmark"></span> </div>
                                            </div>
                                            <div class="col-md-10 col-lg-11 py-4">
                                                <p class="m-0 font-12 text-capitalize"><strong>airtel money</strong></p>
                                                <p class="font-10px m-0">provide your <strong>aitel money phone number</strong> and a payment request will be sent to your phone</p>
                                            </div>
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="form-group col-sm-12 col-md-12 p-0 my-2">
                                        <label for="mpesa-phone" class="text-capitalize">mpesa phone number</label>
                                        <input v-model="phoneNumber" type="text" class="form-control" id="mpesa-phone" placeholder="eg 07123...">
                                    </div>
                                </div>
                                <div class="form-group col-sm-12 col-md-12 p-0">
                                    <label for="number-plate" class="text-capitalize">car's registration number</label>
                                    <input v-model="numberPlate" type="text" class="form-control" id="number-plate" placeholder="eg KAA 1...">
                                </div>
                                <div class="col-12 p-0 text-uppercase mt-5">
                                    <button type="button" class="btn btn-primary text-white font-14 w-100 p-4 center mx-0 transaction-form-btn"><span class="btn-text text-uppercase font-12">proceed to checkout | ksh {{feesAndCharge.unitFeeAmount}}</span> <i data-feather="arrow-right" class="ml-2 float-right pull-right"></i></button>
                                </div>

                            </form>
                        </div>

                        <div class="col-12 d-none p-0 animated details-confirm">
                            <div class="">
                                <p class="mb-5 pb-2 text-capitalize">
                                    <span class="back-toform" title="back to transactions form"><i data-feather="arrow-left"  class="mr-3 float-left"></i></span><strong>confirm your transaction details</strong>
                                </p>
                                <hr class="mt-1 p-0">
                                <p><strong class="text-capitalize">transaction details</strong></p>

                                <div class="transactions-details-container text-capitalize">
                                    <p class="m-0"><span>parking zone</span></p>
                                    <strong>{{feesAndCharge.zone}}</strong>
                                    <hr>
                                    <p class="m-0"><span>vehicle type</span></p>
                                    <strong>{{feesAndCharge.feeDescription}}</strong>
                                    <hr>

                                    <p class="m-0"> <span>plate number</span></p>
                                    <strong class="text-uppercase">{{numberPlate}}</strong>
                                    <hr>
                                    <div class=" text-right amount-container">
                                        <p><span class="text-uppercase text-left">sub total</span> <strong> KES {{feesAndCharge.unitFeeAmount}}</strong></p>
                                    </div>
                                    <hr>

                                    <form>

                                        <div class="form-group col-sm-12 col-md-12 p-0">
                                            <label for="number-plate" class="text-capitalize">M-pesa phone number</label>
                                            <input v-model="phoneNumber" type="text" class="form-control" placeholder="eg 0712...">
                                        </div>

                                        <div class="col-12 p-0 text-uppercase mt-5">
                                            <button @click="generateParkingPayment()" type="button" class="btn btn-primary text-white font-14 w-100 p-4 center mx-0">
                                                <div class="btn-txt animated">
                                                    <span class="btn-text text-uppercase font-12">pay kes {{feesAndCharge.unitFeeAmount}}</span>
                                                    <i data-feather="arrow-right" class="ml-2 float-right pull-right">
                                                    </i>
                                                </div>
                                                <div v-if="loading" class="lds-ellipsis animated"><div></div><div></div><div></div><div></div></div>
                                            </button>
                                        </div>

                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--form section-->
    <section id="download" class="clearfix parallax-section">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-center">
                    <h2>NCCG e-Payments Platforms</h2>
                </div>
            </div>
            <!-- row -->

            <!-- row -->
            <div class="row">
                <!-- download-app -->
                <div class="col-sm-4"> <a href="https://play.google.com/store/apps/details?id=com.aw.epayments" class="download-app mb-2"> <img src="img/logo/google-play.png" alt="Image" class="img-responsive"> <span class="pull-left"> <span>available on</span> <strong class="text-white">Google Play</strong> </span> </a> </div>
                <!-- download-app -->

                <!-- download-app -->
                <div class="col-sm-4"> <a href="#" class="download-app mb-2"> <img src="img/logo/ussd.svg" alt="Image" class="img-responsive"> <span class="pull-left"> <span>USSD services</span> <strong class="text-white">*235#</strong> </span> </a> </div>
                <!-- download-app -->

                <!-- download-app -->
                <div class="col-sm-4"> <a href="#" class="download-app mb-2"> <img src="img/logo/web.svg" alt="Image" class="img-responsive"> <span class="pull-left"> <span>available on</span> <strong class="text-white">Web Portal</strong> </span> </a> </div>
                <!-- download-app -->
            </div>
            <!-- row -->
        </div>
        <!-- contaioner -->
    </section>
    <!-- download -->
    <!--			footer-->
    <footer id="footer" class="clearfix">
        <!-- footer-top -->
        <section class="footer-top clearfix bg-dark text-capitalize">
            <div class="container">
                <div class="row">
                    <!-- footer-widget -->
                    <div class="col-sm-3">
                        <div class="footer-widget news-letter mb-5">
                            <div class="">
                                <h3 class="text-white text-capitalize">Nairobi County Government Self Service Portal</h3>
                                <p class="font-12">Make payments easily and quickly. Create account and get to enjoy paying for Kisumu City County services online.</p>
                            </div>
                            <img src="img/logo/county_logo.png" height="60px;" class="pr-2"> </div>
                    </div>
                    <!-- footer-widget -->

                    <!-- footer-widget -->
                    <div class="col-sm-3">
                        <div class="footer-widget mb-5">
                            <h3 class="text-white">My account</h3>
                            <ul>
                                <li><a href="#">my profile</a></li>
                                <li><a href="#">transactions history</a></li>
                                <li><a href="#">my assets</a></li>
                                <li><a href="#">my bills</a></li>
                                <li><a href="#">Change Password</a></li>
                                <li><a href="#">log out</a></li>
                            </ul>
                        </div>
                    </div>
                    <!-- footer-widget -->

                    <!-- footer-widget -->
                    <div class="col-sm-3">
                        <div class="footer-widget social-widget mb-5">
                            <h3 class="text-white">quick links</h3>
                            <ul>
                                <li><a href="daily-parking.html">daily parking</a></li>
                                <li><a href="parking-penalty.html">parking penalties</a></li>
                                <li><a href="pay-bill.html">pay for bills</a></li>
                                <li><a href="seasonal-parking.html">seasonal parking</a></li>
                                <li><a href="#">e-construction</a></li>
                                <li><a href="#">FAQ</a></li>
                            </ul>
                        </div>
                    </div>
                    <!-- footer-widget -->

                    <!-- footer-widget -->
                    <div class="col-sm-3">
                        <div class="footer-widget news-letter mb-5">
                            <h3 class="text-white text-capitalize">Android app</h3>
                            <p class="font-12">Download The Mobile App From Google Playstore And Get To Enjoy County Services At The Tip Of Your Fingers</p>
                            <a href="https://play.google.com/store/apps/details?id=com.aw.epayments" class="p-3"> <img src="img/logo/google-play-66442243.svg" alt="Google Play Store Badge" class="FooterNew-appStoreIcon-image"> </a>
                            <!-- form -->
                            <form action="" class="d-none">
                                <input type="email" class="form-control" placeholder="Your E-mail">
                                <button type="submit" class="btn btn-primary">Subscribe</button>
                            </form>
                            <!-- form -->
                        </div>
                    </div>
                    <!-- footer-widget -->
                </div>
                <!-- row -->
            </div>
            <!-- container -->
        </section>
        <!-- footer-top -->

        <div class="footer-bottom clearfix  bg-dark">
            <div class="container">
                <p class="d-none">Copyright © 2018 Kemiio </p>
                <div class="row">
                    <div class="col-6 p-md-0 px-sm-3">
                        <p class="text-capitalize m-0 text-white font-12">Help Line</p>
                        <h4 class="SiteStat-value text-white">+254 704 549 859</h4>
                    </div>
                    <div class="col-6 px-sm-3 p-md-0 d-none"> <img src="img/logo/powered_nbk_center.png" class="float-right" height="35px"> </div>
                </div>
            </div>
        </div>
        <!-- footer-bottom -->
    </footer>
    <!--			footer-->

    <!--scripts-->
    </body>
    </html>

</template>

<script>

    /* eslint-disable */
    import $ from 'jquery';
    import ScrollMagic from 'scrollmagic';
    import {paysol, biller, execute, execute2, executeDownload, getRight, parking} from "@/api";

    export default {
        name: "DailyParking",
        data() {
            return{
                payConfirmationPop: false,
                loading: false,
                loadingPayment: false,
                paymentReceived: false,
                billGenerated: {
                    billNo: null,
                    payBillNo: null,
                    amount: null,
                    success: null
                },

                numberPlate: null,
                phoneNumber: null,
                incomeTypes: [{
                        incomeTypeId: null,
                        incomeTypeDescription: null,
                        incomeTypePrefix:null,
                        fundDescription: null,
                        status: null,
                        departmentID: null,
                        zoneCategory: null,
                        zoneCategoryID: null,
                        onUssd: null,
                        dailyTargets: null,
                        monthlyTargets: null,
                        financialYearTargets: null,
                        brimsCode: null
                    }],
                incomeType: {
                    incomeTypeId: null,
                    incomeTypeDescription: null,
                    incomeTypePrefix:null,
                    fundDescription: null,
                    status: null,
                    departmentID: null,
                    zoneCategory: null,
                    zoneCategoryID: null,
                    onUssd: null,
                    dailyTargets: null,
                    monthlyTargets: null,
                    financialYearTargets: null,
                    brimsCode: null
                },
                feesAndCharges: [{
                feeId: null,
                feeDescription:null,
                unitOfMeasure: null,
                unitFeeAmount: null,
                accountNo: null,
                incomeTypeId: null,
                feeType: null,
                accountDesc: null,
                costCenterNo: null,
                zone: null,
                typeDescription: null,
                prorated: null,
                duration: null,
                brimsCode: null
            }],
                feesAndCharge: {
                feeId: null,
                feeDescription:null,
                unitOfMeasure: null,
                unitFeeAmount: null,
                accountNo: null,
                incomeTypeId: null,
                feeType: null,
                accountDesc: null,
                costCenterNo: null,
                zone: null,
                typeDescription: null,
                prorated: null,
                duration: null,
                brimsCode: null
            },
                transaction: {
                    id: null,
                    account_to: null,
                    account_from: null,
                    amount: null,
                    ref: null,
                    transaction_code: null,
                    payment_mode: null,
                    company: null,
                    status: null,
                    merchant_request_id: null,
                    date: null,
                    balance: null,
                    names: null,
                    email: null,
                    posted: null,
                    postedToEric: null,
                    TransactionDesc: null,
                    zone: null,
                    street: null,
                    user_id: null,
                    username: null,
                    user_full_name: null,
                    msisdn: null,
                    postedToBiller: null,
                    queried: null,
                    postedToHealth: null,
                    currency: null,
                    message: null,
                    response: null,
                    remarks: null
                },
                receiptDetails: {
                    id: '',
                    receiptNo: null,
                    billNo: '',
                    detailAmount: '',
                    receiptAmount: '',
                    billBalance: '',
                    costCenterNo: '',
                    accountNo: '',
                    incomeTypeDescription: '',
                    feeID: '',
                    wardID: '',
                    subCountyID:'',
                    currency: '',
                    source: '',
                    transactionCode: '',
                    paidBy: '',
                    dateCreated: '',
                    dateModified: '',
                    createdBy: '',
                    modifiedBy: '',
                    isActive: '',
                    status: ''
                },

            }
        },
        mounted: function () {
            this.mainJS()
            this.getIncomeTypes()
        },
        watch:{
            incomeType: function () {
                this.getFeesAndCharges()
            } ,
        },
        methods:{
            gotTo(route){
                this.$router.push(route);
            },
            mainJS() {
                $(window).on('load', function() {
                    $('#myModal').modal('show');
                    function explode(){
                        $('#pop').removeClass('d-none');
                        $('#pop').addClass('fadeIn');
                        $('.hand-phone').addClass('bounceInUp')

                    }

//	hiding biz creator

                    $('#create-next').on('click', function(){
                        bizinfo_hide();
                    });
                    $('#location-next').on('click', function(){
                        location_hide();
                    });
                    $('#owners-next').on('click', function(){
                        owner_hide();
                    });

//	span clicking


                    $('.start-clickable').on('click', function(){
                        bizinfo_hide2();

                    });

                    // Get the element, add a click listener...
                    document.getElementById("point-indicator").addEventListener("click", function(e) {
                        // e.target is the CHANGING Element!
                        // If it was an input item
                        if(e.target && e.target.matches(".location-clickable")) {
                            // List item found!  Output the ID!
                            location_hide();
                        }
                    });

                    // Get the element, add a click listener...
                    document.getElementById("point-indicator").addEventListener("click", function(e) {
                        // e.target is the CHANGING Element!
                        // If it was an input item
                        if(e.target && e.target.matches(".info-clickable")) {
                            // List item found!  Output the ID!
                            bizinfo_hide();
                        }
                    });


                    // Get the element, add a click listener...
                    document.getElementById("activity-btns").addEventListener("click", function(e) {
                        // e.target is the CHANGING Element!
                        // If it was an input item
                        if(e.target && e.target.matches(".text-prev")) {
                            // List item found!  Output the ID!

                            location_hide();
                        }
                    });

                    // Get the element, add a click listener...
                    document.getElementById("location-btns").addEventListener("click", function(e) {
                        // e.target is the CHANGING Element!
                        // If it was an input item
                        if(e.target && e.target.matches(".text-prev")) {
                            // List item found!  Output the ID!

                            bizinfo_hide2();
                        }
                    });

                    // Get the element, add a click listener...
                    document.getElementById("owner-btns").addEventListener("click", function(e) {
                        // e.target is the CHANGING Element!
                        // If it was an input item
                        if(e.target && e.target.matches(".text-prev")) {
                            // List item found!  Output the ID!

                            bizinfo_hide();
                        }
                    });

                    $('location-info .text-prev').on('click',function(){
                        bizinfo_hide2();
                    });


//	$('#activity-next').on('click', function(){
//		activity_hide();
//	});
                    function bizinfo_hide2(){
//			$('.biz-info').addClass('d-none').addClass('bounceOutRight').removeClass('bounceInLeft');
                        $('.biz-info').removeClass('d-none').removeClass('bounceOutRight').addClass('bounceInLeft').siblings().addClass('d-none').addClass('bounceOutRight').removeClass('bounceInLeft');
                        $('.point-indicator span').eq(0).addClass('active').addClass('start-clickable').siblings().removeClass('active');
                    }

                    function bizinfo_hide(){
//			$('.biz-info').addClass('d-none').addClass('bounceOutRight').removeClass('bounceInLeft');
                        $('.location-info').removeClass('d-none').removeClass('bounceOutRight').addClass('bounceInLeft').siblings().addClass('d-none').addClass('bounceOutRight').removeClass('bounceInLeft');
                        $('.point-indicator span').eq(1).addClass('active').addClass('info-clickable').siblings().removeClass('active');
                    }

                    function location_hide(){
                        $('.owners-info').removeClass('d-none').removeClass('bounceOutRight').addClass('bounceInLeft').siblings().addClass('d-none').addClass('bounceOutRight').removeClass('bounceInLeft');
                        $('.point-indicator span').eq(2).addClass('active').addClass('location-clickable').siblings().removeClass('active');
                    }
                    function owner_hide(){
                        $('.activity-info').removeClass('d-none').removeClass('bounceOutRight').addClass('bounceInLeft').siblings().addClass('d-none').addClass('bounceOutRight').removeClass('bounceInLeft');
                        $('.point-indicator span').eq(3).addClass('active').addClass('owner-clickable').siblings().removeClass('active');
                    }
//	function owner_hide(){
//			$('.activity-info').addClass('d-none').addClass('bounceOutRight').removeClass('bounceInLeft');
//			$('.activity-info').removeClass('d-none').removeClass('bounceOutRight').addClass('bounceInLeft');
//		}




                    function popitem(){
                        $('.pop-item').addClass('tada');
                    }
                    setTimeout(explode, 5000);
                    setTimeout(popitem, 5200);

                    $('.close-pop').on('click',function(){
                        closepop();
                    });

                    $('#close-pricepop').on('click',function(){
                        closepaypop();
                    });



                    function closepop(){
                        setTimeout($('#pop').addClass('d-none'),2000);
                        $('#pop').removeClass('fadeIn');
                        $('.pop-item').removeClass('tada');

                        $('#pop').addClass('zoomOut');
                        $('.pop-item').addClass('rollOut');

                    }

                    function closereceiptform(){
                        setTimeout($('#search-receipt').addClass('d-none'),2000);
                        $('#search-receipt').removeClass('fadeIn');
                        $('#get-receipt-container').removeClass('tada');

                        $('#search-receipt').addClass('zoomOut');
                        $('#get-receipt-container').addClass('rollOut');

                    }


                    function closepaypop(){
                        $('#pay-confirmation-pop').addClass('d-none');
                        $('#pay-confirmation-pop').removeClass(' zoomOut');
                    }


                    function showreceiptform(){
                        $('#search-receipt').removeClass('zoomOut');
                        $('#search-receipt').removeClass('d-none');
                        $('#search-receipt').addClass('fadeIn');
                        $('#get-receipt-container').removeClass('rollOut');
                        $('#get-receipt-container').addClass('tada');
                    }

                    $('.close-receipt-form').on('click',function(){
                        closereceiptform();
                    });

                    $('#get-a-receipt').on('click',function(){
                        showreceiptform();
                    });

                    $('.get-a-receipt').on('click',function(){
                        showreceiptform();
                    });


                });

                (function($) {

                    function closesublinks() {
                        $(".construction1").addClass("left");
                        $(".wallet1").addClass("left");
                        $(".parking1").addClass("left");
                        $(".rent1").addClass("left");
                        $(".unified-biz-permit1").addClass("left");
                        $('.land-rate1').addClass("left");

                    }

                    $(".option").on("click", function() {
                        checkWidth();
                    });
                    $(".hidden-links-container").on("click", function() {
                        $(this).hide();
                    });

                    function checkWidth() {
                        var windowSize = $(window).width();

                        if (windowSize < 993) {
                            $(".hidden-links-container").css("display", "flex");
                        }
                    }

                    // Execute on load

                    $(".option").on("click", function() {
                        closesublinks();
                        $(this).addClass("active").siblings().removeClass("active");
                        $(this).children(".active-triangle").removeClass("display-none");
                        $(this).siblings().children(".active-triangle").addClass("display-none");
                    });

                    $('.business-permit').on("click", function() {
                        $('.unified-biz-permit1').removeClass("left");
                    });

                    $('.construction').on("click", function() {
                        closesublinks();
                        $('.construction1').removeClass("left");
                    });

                    $('.E-Wallet').on("click", function() {
                        $('.wallet1').removeClass("left");
                    });

                    $('.rents').on("click", function() {
                        $('.rent1').removeClass("left");
                    });

                    $('.parking').on("click", function() {
                        $('.parking1').removeClass("left");
                    });

                    $('.land-rates').on("click", function() {
                        $('.land-rate1').removeClass("left");
                    });

                    $(".name-discount").on("click", function() {
                        $(".toogle-user-container").slideToggle();
                    });

                    $(".dp").on("click", function() {
                        $(".toogle-user-container").slideToggle();
                    });

                    $(".toogle-user-container").on("click", function() {
                        $(".toogle-user-container").slideUp();
                    });

                    //	payment methods mpesa direct
                    $(".m-pesa-direct").on("click", function() {
                        $(".m-pesa-direct-inputs").slideToggle().siblings(".payment-details").slideUp();
                        $(this).siblings().children("span").css("opacity", "0");
                        $(".direct-indicator").css("opacity", "1");


                    });

                    //	payment methods mpesa standard
                    $(".mpesa-standard").on("click", function() {
                        $(".m-pesa-standard-inputs").slideToggle().siblings(".payment-details").slideUp();
                        $(this).siblings().children("span").css("opacity", "0");
                        $(".std-indicator").css("opacity", "1");
                    });

                    //	payment methods credits
                    $(".viza-master").on("click", function() {
                        $(".credit-payment-inputs").slideToggle().siblings(".payment-details").slideUp();
                        $(this).siblings().children("span").css("opacity", "0");
                        $(".visa-indicator").css("opacity", "1");
                    });

                    //	payment methods airtel
                    $(".airtell").on("click", function() {
                        $(".airtell-inputs").slideToggle().siblings(".payment-details").slideUp();
                        $(this).siblings().children("span").css("opacity", "0");
                        $(".airtel-indicator").css("opacity", "1");
                    });


                    $('.user-nav .wrap').on('click', function(){
                        $('.user-nav .wrap').removeClass('display-tri');
                        $('.user-nav .wrap').removeClass('active-item')
                        $(this).addClass('display-tri');
                        $(this).addClass('active-item');
                    });

                    $('.acount-details-form input').on('change', function(){
                        $('.submit-btn').prop("disabled", false);
                    })



                })(jQuery);

                $(document).ready(function() {
                    // Toggles the side menu and overlay
                    $('.toogle-btn').on('click', function() {
                        $(".overlay").toggleClass("display-none");
                        // $('.toogle-menu-container').toggleClass('hide show');
                    })

                    // Closes the side menu and removes the overlay when the overlay is clicked
                    $('.overlay').on('click', function() {
                        // $('.toogle-menu-container').toggleClass('hide show');
                        $(".overlay").toggleClass("display-none");
                    })

                    // ScrollMagic for Options
                    var controller = new ScrollMagic.Controller();
                    var ourScene = new ScrollMagic.Scene({
                        triggerElement: '.option',
                        triggerHook: 0.7
                    }).setClassToggle('.option', 'fade-in')
                        .addTo(controller)
                });



//this part affects the transactions page
                (function($) {
                    $(".main-transaction-container").on("click", function(){
                        returnpannels();
                        var parent=$(this).parent();
                        $(this).addClass("active-transaction").parent().siblings().children("active-transaction").removeClass("active-transaction");
                        $(this).siblings(".transaction-more-details").addClass("display-flex");
                        $(this).siblings(".transaction-more-details").slideDown();
                        parente.siblings(".transaction-more-details").removeClass("display-flex");

//		$(".transaction-more-details").eq(index).removeClass("display-none");
//		console.log(index);

                    });

                    $(".close-panel-container").on("click", function(){
                        $(this).parent().slideUp();
                        $(this).parent().removeClass("display-flex");
                    });




                    function returnpannels()
                    {
                        $(".main-transaction-container").removeClass("active-transaction");
                        $(".transaction-more-details").slideUp();
                        $(".transaction-more-details").removeClass("display-flex");

                    }


//	showing and removing assets form

                    $('#add-asset').on('click', function(){
                        showassetsform();
                    });

                    $('#close-asset-form').on('click', function(){
                        hideassetsform();
                    })

                    function showassetsform(){
                        $(".assets-form-container").removeClass('left');
                        $('.assets-container').addClass('right');

                    }

                    function hideassetsform(){
                        $(".assets-form-container").addClass('left');
                        $('.assets-container').removeClass('right');

                    }
                    $('.drop-title').on('click', function(){
                        $(this).parent().siblings('.drop-container').slideToggle();
                    })

                    $('.assets-container .drop-container li').on('click', function(){
                        var thetext=$(this).text();
                        $('.assets-container .the-assets-text').text(thetext);
                        $('.drop-container').slideUp();
                        console.log(thetext);
                    });


//	for the assets form

                    $('.assets-form-container .drop-container li').on('click', function(){
                        var thetext=$(this).text();
                        $('.assets-form-container .the-assets-text').text(thetext);
                        $('.drop-container').slideUp();
                        console.log(thetext);
                    });


//	interchanging forms
                    $('.the-assets-form-group li').on('click', function(){
                        $('.the-assets-form').addClass('d-none');
                    });

                    $('.nyumba').on('click',function(){
                        $('.house-form-inputs').removeClass('d-none');
                    });

                    $('.gari').on('click',function(){
                        $('.car-form-inputs').removeClass('d-none');
                    });

                    $('.soko').on('click',function(){
                        $('.stalls-form-inputs').removeClass('d-none');
                    });

                    $('.gari').on('click',function(){
                        $('.car-form-inputs').removeClass('d-none');
                    });

                    $('.shamba').on('click',function(){
                        $('.land-form-inputs').removeClass('d-none');
                    });


//	payment method
                    $(".pay-container .pay-method").on('click', function(){
                        $(this).addClass('gray-bg').siblings().removeClass('gray-bg');
                    });

//	closing price container


//	function showhideprice2(parking_zone){
//		if(parking_zone!="-- select off-street zone --"){
//		$('.price-container').slideDown();
//	}
                    function showhideprice(parking_zone, car_type){
                        if((parking_zone!="-- select parking zone --") && (car_type!="-- select vehicle type --")){
                            $('.price-container').slideDown();
                        }
                        else if((parking_zone=="-- select parking zone --") || (car_type=="-- select vehicle type --")){
                            $('.price-container').slideUp();
                        }
                    }

                    $('.close-price-container').on('click', function(){
                        $(this).parent().slideUp();
                    });

                    $('#zone').on('change',function(){
                        var parking_zone=$('#zone option:selected').val();
                        var car_type=$('#car_type option:selected').val();
                        showhideprice(parking_zone, car_type);

                    })

                    $('#car_type').on('change',function(){
                        var parking_zone=$('#zone option:selected').val();
                        var car_type=$('#car_type option:selected').val();
                        showhideprice(parking_zone, car_type);
                    })

                    $('#parking-zone').on('change',function(){

                        var parking_zone=$('#parking-zone option:selected').val();
                        var car_type=$('parking zone category').val();
                        showhideprice(parking_zone, car_type);
                    })


//	back to form
                    $('.back-toform').on('click',function(){
                        $('.details-confirm').addClass('fadeOutRight');
                        $('.details-confirm').addClass('d-none');
                        $('.the-transaction-form').removeClass('d-none');
                        $('.the-transaction-form').addClass('fadeInLeft');
                        $('.the-transaction-form').removeClass('fadeOutLeft');
                    });

                    $('.transaction-form-btn').on('click',function(){
                        $('.details-confirm').removeClass('fadeOutRight');
                        $('.details-confirm').removeClass('d-none');
                        $('.the-transaction-form').removeClass('fadeInLeft');
                        $('.the-transaction-form').addClass('fadeOutLeft');
                        $('.the-transaction-form').addClass('d-none');
                        $('.details-confirm').addClass('fadeInRight');

                    });

                    $('.confirm-btn').on('click', function(){
                        $('.btn-txt').addClass('d-none');
                        $('.lds-ellipsis').removeClass('d-none');




                        setTimeout(popprice, 2200);
                        setTimeout(showloader, 4200);

                    });

                    function popprice(){
                        $('#pay-confirmation-pop').removeClass('d-none');
                    }

                    function showloader(){
                        $('.lds-roller').addClass('fadeOut');
                        $('.lds-roller').addClass('position-absolute');
                        $('.confirmed-pay').addClass('fadeIn');
                        $('.confirmed-pay').removeClass('d-none');
                        $('.transacton-instructions').addClass('d-none');
                        $('.transactions-actions').addClass('fadeIn');
                        $('.transactions-actions').removeClass('d-none');

                        $('.payment-status').text('Received!');
                    }

//	delete pop functions
                    $('.cars-container').on('click','.remove-car', function () {
                        $('.notification-container').addClass('fadeIn');

                        $('.notification-container').removeClass('d-none');

                        $('.notification-container').removeClass('fadeOut');
                        $('.notification-container').removeClass('d-none');
                        $('.notification-container .card').addClass('bounceUp');
                        var title="lamba;";
                        var title=$(this).siblings().text();
                        $('#record-name').text(title);

                        console.log(title);
                    });

                    $('.transactions-details-container').on('click','.remove-car', function () {
                        $('.notification-container').addClass('fadeIn');
                        $('.notification-container').removeClass('d-none');
                        $('.notification-container').removeClass('fadeOut');
                        $('.notification-container').removeClass('d-none');
                        $('.notification-container .card').addClass('bounceUp');
                        var title="lamba;";
                        var title=$(this).parent().siblings().children("strong").text();
                        $('#record-name').text(title);

                        console.log(title);
                    });

                    $('.close-delete').on('click', function(){
                        closedeletealert();
                    });


                    function closedeletealert() {
                        $('.notification-container').addClass('fadeOut');
                        $('.notification-container').addClass('d-none');
                        $('.notification-container .card').addClass('fadeOutDown');

                        setTimeout(function () {
                            $('.notification-container').removeClass('fadeOut');
                            $('.notification-container .card').removeClass('fadeOutDown');
                        }, 1000);
                    }

//	sign up form animations
                    $('.toogle-loginfforms li').on('click', function(){
                        $(this).addClass('active-log').siblings().removeClass('active-log');
                    })

                    function showsignup(){
                        $('#signup-form').removeClass('d-none');
                        $('#signup-form').removeClass('bounceOutLeft');
                        $('#signup-form').addClass('bounceInRight');
                        $('#login-form').addClass('d-none');
                        $('#login-form').addClass('bounceOutLeft');

                    }

                    function showlogin(){
                        $('#login-form').removeClass('d-none');
                        $('#login-form').removeClass('bounceOutLeft');
                        $('#login-form').addClass('bounceInRight');
                        $('#signup-form').addClass('d-none');
                        $('#signup-form').addClass('bounceOutLeft');

                    }

                    $('.show-signup').on('click', function(){
                        showsignup();
                    });

                    $('.show-login').on('click', function(){
                        showlogin();
                    });


                    $('.profile-nav .nav-item a, .transactions-link').on('click', function(){
                        $('.my-profile-linker').addClass('d-none');
                        $('.my-profile-linker').removeClass('fadeIn');
                        $(this).addClass('active').parent().siblings().children().removeClass('active');
                        $('.profile-loader').removeClass('d-none');
                        $('.profile-loader').addClass('fadeIn');
                        $('.txt-loader').removeClass('d-none');
                        $('.account-txt-block p,.account-txt-block h4').addClass('d-none');
                        $('.account-txt-block').addClass('mb-5');
                        $('#transactions-minor').removeClass('d-none');
                        $('#transactions-main').removeClass('fadeIn');
                        $('#transactions-main').addClass('d-none');
                        setTimeout(function () {
                            $('.profile-loader').addClass('d-none');
                            $('.profile-loader').removeClass('col-md-12,col-lg-12');
                            $('.profile-loader').removeClass('fadeIn');
                            $('.account-txt-block p,.account-txt-block h4').removeClass('d-none');
                            $('.txt-loader').addClass('d-none');
                            $('.account-txt-block').removeClass('mb-5');
                        }, 1800);





                    });

                    $('.account-link').on('click', function(){
                        $('.account-txt-block h4').text('My account')
                        $('.account-txt-block p').text('Below Are Etails On Your Profile. Do Remember To Save Your Work Once You Have Edited An Item In Your Profile')

                        setTimeout(function () {
                            $('.my-profile-linker').addClass('d-none');
                            $('#my-account').addClass('fadeIn');
                            $('#my-account').removeClass('d-none');


                        }, 1801);
                    });

                    $('.assets-link').on('click', function(){
                        $('.account-txt-block h4').text('My Assets')
                        $('.account-txt-block p').text('This Are Some Of The Assets Registered Under Your Account')

                        setTimeout(function () {
                            $('.my-profile-linker').addClass('d-none');
                            $('#the-assets').addClass('fadeIn');
                            $('#the-assets').removeClass('d-none');

                        }, 1801);
                    });


                    $('.bills-link').on('click', function(){
                        $('.account-txt-block h4').text('My bills')
                        $('.account-txt-block p').text('bellow are a number of bills that were raised for you at the county')

                        setTimeout(function () {
                            $('.my-profile-linker').addClass('d-none');
                            $('#my-bills').addClass('fadeIn');
                            $('#my-bills').removeClass('d-none');

                        }, 1801);
                    });


                    $('.year-list li').on('click',function(){
                        $(this).addClass('checked').siblings().removeClass('checked');
                    })


                    $('.transactions-link').on('click', function(){
                        $('.account-txt-block h4').text('My transactions');
                        $('.account-txt-block p').text('bellow are a number of transactions that you have done within the county');
                        $('.profile-loader').addClass('col-md-12,col-lg-12');
                        $('#transactions-minor').addClass('d-none');



                        setTimeout(function () {

                            $('#transactions-main').addClass('fadeIn');
                            $('#transactions-main').removeClass('d-none');

                            $('.my-profile-linker').addClass('d-none');
                            $('.my-profile-linker').removeClass('fadeIn');

                        }, 1801);


//		create business form changeing


                    });

//	data tables
                    $(document).ready(function() {
                        $('#example').DataTable( {

                            "ordering": false,
                            pageLength: 5,

                        } );


                    } );
                    $(document).ready(function() {
                        $('#bill-table').DataTable( {

                            "ordering": false,
                            pageLength: 5,

                        } );


                    } );
//	data tables

                    $('#carouselExampleSlidesOnly').carousel();

                })(jQuery);

//this part affects the transactions page

            },
            getIncomeTypes(){
                this.incomeTypes.splice(0)
                const data = new FormData();
                data.append("function", "getIncomeTypes");
                data.append("incomeTypePrefix", "PKN");
                execute(data,biller)
                    .then((res) =>{
                        if (res.data.success) {
                            this.incomeTypes = res.data.data.incomeTypes
                        }else{
                            alert(res.data.message)
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message =e.message
                    });
            },
            getFeesAndCharges(){
                this.feesAndCharges.splice(0)
                const data = new FormData();
                data.append("function", "getFeesAndCharges");
                data.append("incomeTypeId", this.incomeType.incomeTypeId);
                execute(data,biller)
                    .then((res) =>{
                        if (res.data.success) {
                            this.feesAndCharges = res.data.data.feesAndCharges
                        }else{
                            alert(res.data.message)
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message =e.message
                    });
            },
            generateParkingPayment(){
                this.loading = true
                const data = new FormData();
                data.append("function", "generateParkingPayment");
                data.append("numberPlate", this.numberPlate);
                data.append("feeId", this.feesAndCharge.feeId);
                data.append("zone", this.feesAndCharge.zone);
                data.append("names", "Self Service");
                data.append("phoneNumber","");
                data.append("idNo", "");
                data.append("username", "");
                data.append("subCountyID", "");
                data.append("subCountyName", "");
                data.append("wardID", "");
                data.append("wardName", "");
                data.append("customerPhoneNumber", "");
                data.append("description", "");
                execute(data,parking)
                    .then((res) =>{
                        this.loading = false
                        if (res.data.success) {
                            this.billGenerated = res.data.data.billGenerated
                            this.customerPayBillOnline()
                        }else{
                            alert(res.data.message)
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message =e.message
                    });
            },
            customerPayBillOnline() {
                this.payConfirmationPop = true;
                this.loadingPayment = true;

                const data = new FormData();
                data.append("function", "customerPayBillOnline");
                data.append("payBillNumber", this.billGenerated.payBillNo);
                data.append("amount", this.billGenerated.amount);
                data.append("accountReference", this.billGenerated.billNo);
                data.append("transactionDesc", this.billGenerated.billNo);
                data.append("phoneNumber", this.phoneNumber);
                data.append("token", "im05WXYH2rwRruPjCICieOs8m4E8IoltnDEhyPUv6bnB9cU60gD48SnJPC6oh7EpsPaAUGC8wqIdtVVjGlWLxqFssshxMHxHjEQJ");
                execute2(data, paysol)
                    .then((res) => {
                        if (res.data.success) {
                            // Call checkPayment every 2 seconds until it returns true
                            const intervalId = setInterval(() => {
                                this.checkPayment()
                                    .then((result) => {
                                        if (result) {
                                            // If the condition is true, clear the interval
                                            clearInterval(intervalId);
                                        }
                                    })
                                    .catch((error) => {
                                        console.error(error);
                                    });
                            }, 2000);
                        } else {
                            alert(res.data.message);
                        }
                    })
                    .catch((e) => {
                        // Handle error
                        this.message = e.message;
                    });
            },
            checkPayment() {
                this.loadingPayment = true;
                const data = new FormData();
                data.append("function", "checkPayment");
                data.append("accountRef", this.billGenerated.billNo);

                return new Promise((resolve, reject) => {
                    execute(data, biller)
                        .then((res) => {
                            if (res.data.success) {
                                this.loadingPayment = false;
                                this.transaction = res.data.data.transaction;
                                this.receiptDetails = res.data.data.receipt.receiptDetails
                                this.paymentReceived = true
                                resolve(true);

                               /* if (res.data.data.Push.callback_returned === "PAID") {
                                    this.loadingPayment = false;
                                    this.transaction = res.data.data.transaction;
                                    this.receiptDetails = res.data.data.receipt.receiptDetails
                                    this.paymentReceived = true
                                    resolve(true);
                                }
                                else {
                                    resolve(false);
                                }*/
                            } else {
                                resolve(false);
                               // reject(new Error(res.data.message));
                            }
                        })
                        .catch((error) => {
                            reject(error);
                        });
                });
            },

            printReceipt() {
                if (this.receiptDetails.receiptNo == null) {
                    alert("Try again later");
                } else {
                    //  this.$refs.closeReceived.click();
                    localStorage['params'] = JSON.stringify({
                        receiptNo: this.receiptDetails.receiptNo
                    })
                    const routeData = this.$router.resolve({name: 'receipt'});
                    window.open(routeData.href, '_blank');
                }
            }

        }
    }
</script>

<style scoped>

</style>