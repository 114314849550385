<template>

<head>
  <meta charset="utf-8">
  <link rel="icon" type="image/jpg" href="img/receipt-images/Untitledlogo2.png">
  <title>County Receipt</title>
  <link href='https://fonts.googleapis.com/css?family=Aldrich' rel='stylesheet'>
  <link href="https://fonts.googleapis.com/css?family=Roboto+Mono:400,500,700&display=swap" rel="stylesheet">
  <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">
  <link href="https://unpkg.com/ionicons@4.5.10-0/dist/css/ionicons.min.css" rel="stylesheet">

  <link rel="stylesheet" href="css/receipt-print/icon.css">
  <link rel="stylesheet" href="css/receipt-print/uno.css">
  <link rel="stylesheet" href="css/receipt-print/dos.css">
  <link rel="stylesheet" href="css/receipt-print/tres.css">
  <link rel="stylesheet" href="css/receipt-print/cuatro.css">
  <link rel="stylesheet" href="css/receipt-print/color-print.css">

    </head>

    <body>
    <div class="container-fluid">
        <div class="topSpace noPrint"></div>
        <div class="main row">
            <div class="topBar col-12">
                <div class="loop noPrint">
                    <img src="img/receipt-images/loop.png" alt="">
                </div>
                <div class="row align-items-center">
                    <div class="col-7">
                        <h1 class="receipt">Parking Receipt</h1>
                    </div>
                    <div class="logo col-5">
                        <div class="row align-items-center no-gutters">
                            <div class="col-5">
                                <img src="img/receipt-images/Untitledlogo2.png" alt="">
                            </div>
                            <div class="col-7">
                                <div class="logoContent">
                                    <p>Nairobi City County
                                        <br>
                                        City Hall
                                        <br>
                                        PO Box 30075-00100
                                        <br>
                                        Nairobi, Kenya
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="sec1 container">
                <div class="row">
                    <div class="sec1Left pl-50px col-5">
                        <div class="date">
                            <p class="pHead">Date  printed</p>
                            <p class="secPContent">{{receiptDetails.dateCreated}}</p>
                        </div><br>
                        <div class="paidBy">
                            <p class="pHead">Paid by</p>
                            <p class="secPContent">
                                {{receiptDetails.paidBy}},<br>
                                {{receiptDetails.transactionCode}}<br>
                                {{receiptDetails.source}}
                            </p>
                        </div>
                    </div>
                    <div class="sec1Right pr-50px col-7">
                        <div class="receiptNo">
                            <p class="pHead">Receipt No</p>
                            <p class="secPContent" id="receipt">{{receiptDetails.receiptNo}}</p>
                            <div class="barImg">
                                <img class="imgBar" src="img/receipt-images/Untitledbar.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="interSec container">
                <div class="interSecContent row">
                    <p class="description col-5 pl-50px">Description</p>
                    <p class="time col-3 text-center">Time</p>
                    <p class="total col-4 text-right pr-50px">Total Paid</p>
                </div>
            </div>

            <div class="interSecDet container">

                <div class="interSecDetContent row"   :key="index" :value="item" v-for="(item, index) in receiptInfos">
                    <p class="descriptionDet pl-50px col-5">
                        {{item.feeDescription}}
                        <br>
                        <span class="font12">{{item.description}} for {{item.customer}} at {{item.zone}}</span>
                    </p>
                    <p class="timeDet col-3 text-center">{{item.dateCreated}}</p>
                    <p class="totalDet col-4 text-right pr-50px"  id="ksh">KES {{item.receiptAmount}}</p>
                </div>

            </div>

            <div class="slantMain container">
                <div class="row align-items-start">
                    <div class="noteSlant col-5">
                        <p class="pHead">Note</p>
                        <p class="font12">All amounts are in KES and VAT inclusive where applicable</p>
                    </div>
                    <div class="col-3 px-0">
                        <p class="tHead">Bill Amount</p>
                        <p class="tHead">Amount Received</p>
                        <p class="tHead">Balance</p>
                    </div>
                    <div class="col-4 px-0">
                        <p class="kshHead text-right pr-50px">KES {{receiptDetails.detailAmount}}</p>
                        <p class="kshHead text-right pr-50px">KES {{receiptDetails.receiptAmount}}</p>
                        <p class="kshHead text-right pr-50px">KES {{receiptDetails.billBalance}}</p>
                    </div>
                </div>
            </div>

            <div class="vidImg container  noPrint">
                <div class="vid container">
                    <iframe class="youtube" src="https://www.youtube.com/embed/fHektLivpgc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope;
           picture-in-picture" allowfullscreen></iframe>
                </div>
                <div class="imgHolder container noPrint">
                    <div class="img">
                        <a href="https://www.booking.com/hotel/ke/villa-rosa-kempinski.html" target="_blank">
                            <img src="https://cf.bstatic.com/xdata/images/hotel/max1024x768/485547971.jpg?k=ad3e03f2c61aacfac5b1107d522a4a0f6497b700f579005d02d28c8fecc553ea&o=&hp=1" alt="" width="528px" height="98px">
                        </a>
                    </div>
                    <div class="img">
                        <a href="https://safaricomsacco.com/" target="_blank">
                            <img src="https://nairobiwire.com/wp-content/uploads/2022/04/how-to-pay-safaricom-sacco-loan.jpg" alt="" width="528px" height="98px">
                        </a>
                    </div>
                    <div class="img">
                        <a href="https://www.tripadvisor.com/Attraction_Review-g294207-d12110139-Reviews-Uhuru_Park_Nairobi-Nairobi.html" target="_blank">
                            <img src="https://dynamic-media-cdn.tripadvisor.com/media/photo-o/0e/5f/fa/b7/uhuru-park-nairobi.jpg?w=2000&h=-1&s=1" alt="" width="528px" height="98px">
                        </a>
                    </div>
                    <div class="img" id="img4">
                        <a href="https://www.uonbi.ac.ke/" target="_blank">
                            <img src="https://images.theconversation.com/files/312035/original/file-20200127-81369-1u2rdn0.jpg?ixlib=rb-1.1.0&q=45&auto=format&w=926&fit=clip" alt="" width="528px" height="98px">
                        </a>
                    </div>
                </div>

            </div>
            <footer>
                <div class="bill container">
                    <div class="row">
                        <div class="col-6">
                            <p>Served by: {{receiptDetails.paidBy}}</p>
                        </div>
                        <div class="col-6">
                            <p class="text-right">Bill No: <span class="billNo">{{receiptDetails.billNo}}</span></p>
                        </div>
                    </div>
                </div>
                <div class="footer container">
                    <div class="row">
                        <div class="footerLogo col-7">
                            <a href="http://epayments.nairobi.go.ke/">epayments.nairobi.go.ke</a>
                        </div>
                        <div class="footerRight col-5">
                            <p><span>Powered by :</span><img :src="county.poweredByLogo" alt=""></p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
        <div class="endSpace noPrint"></div>
        <a href="javascript:window.print();" class="noPrint"><i class="icon ion-md-print"></i></a>
    </div>

    </body>

</template>

<script>
    import {execute, biller} from "@/api";

    export default {
        name: "printReceipt",
        data() {
            return {
                date: null,
                receiptDetails: {
                    id: null,
                    receiptNo: null,
                    billNo: null,
                    detailAmount: null,
                    receiptAmount: null,
                    billBalance: null,
                    costCenterNo: null,
                    accountNo: null,
                    incomeTypeDescription: null,
                    feeID: null,
                    wardID: null,
                    subCountyID: null,
                    currency: null,
                    source: null,
                    transactionCode: null,
                    paidBy: null,
                    dateCreated: null,
                    dateModified: null,
                    createdBy: null,
                    modifiedBy: null,
                    isActive: null,
                    status: null,
                },
                link: '',
                receiptInfos: [{
                    id: null,
                    receiptNo: null,
                    billNo: null,
                    feeID: null,
                    feeDescription: null,
                    billTotal: null,
                    receiptAmount: null,
                    billBalance: null,
                    customer: null,
                    clientPhoneNo: null,
                    description: null,
                    receiptDate: null,
                    printCount: null,
                    wardID: null,
                    subCountyID: null,
                    subCountyName: null,
                    wardName: null,
                    dateCreated: null,
                    dateModified: null,
                    createdBy: null,
                    modifiedBy: null,
                    printedBy: null,
                    updated: null,
                    isActive: null,
                    status: null,
                    zone: null,
                    departmentID: null,
                    department: null,
                    idNo: null,
                    phoneNumber: null,
                    names: null,
                    customerPhoneNumber: null,
                    category: null,
                    incomeTypePrefix: null
                }],

                county: {
                    id: null,
                    countyID: null,
                    countyName: null,
                    tagline: null,
                    smsusername: null,
                    smspassword: null,
                    contact: null,
                    center: null,
                    address: null,
                    email: null,
                    headline: null,
                    tagline2: null,
                    ussd: null,
                    logo: null,
                    bank: null,
                    powered: null,
                    stateLogo: null,
                    seal: null,
                    signature: null,
                    lat: null,
                    lng: null,
                    link: null,
                    paysol: null,
                    poweredByLogo: null,
                    mobileApp: null,
                    selfService: null
                },
            };
        },
        mounted() {
            let params = {}
            try {
                params = JSON.parse(localStorage['params'])
                console.log('####' + params.receiptNo)
                this.getReceipt(params.receiptNo);
            } catch (error) {
                // ignore
            }

        },
        methods: {
            showDate() {
                const dateObj = new Date();
                const currentDate =
                    dateObj.getDate() +
                    "/" +
                    dateObj.getMonth() +
                    "/" +
                    dateObj.getFullYear();
                return currentDate;
            },
            formatPrice(value) {
                let val = (value / 1).toFixed(2).replace(".", ",");
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            },

            getReceipt(receiptNo) {
                const data = new FormData();
                data.append("function", "getReceipt");
                data.append("receiptNo", receiptNo);


                execute(data, biller)
                    .then((res) => {
                        if (res.data.success) {
                            this.receiptDetails = res.data.data.receiptDetails;
                            this.receiptInfos = res.data.data.receiptInfos;
                            this.county = res.data.data.county;
                            this.link = "https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=" + this.receiptDetails.receiptNo;
                        } else {
                            alert(res.data.data.message);
                        }
                    })
                    .catch((e) => {
                        alert(e.message);
                    });
            },
        },
    };
</script>

<style scoped>

</style>