<template>

    <html>
    <head>
        <meta charset="utf-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <meta name="author" content="Theme Region">
        <meta name="description" content="">
        <title>Renew Business  Permit</title>
        <!-- Bootstrap CSS -->
        <link href="https://fonts.googleapis.com/css?family=Open+Sans" rel="stylesheet">
        <link href="css/animate/animate.min.css" rel="stylesheet">
        <link rel="stylesheet" href="css/bootstrap.css">
        <!--	bootstrap css-->
        <!--  CSS -->
        <link rel="stylesheet" href="css/navigation-temp.css">
        <link rel="stylesheet" href="css/style.css">
        <!-- <link rel="stylesheet" href="css/style2.css"> -->

        <link rel="stylesheet" href="css/form.css">
    </head>

    <body>
    <!--	!--	MENU CONTROLS-->

    <!-- header -->
    <!--		navigation bar -->
    <div class="inline-search-block fixed with-close big" id="toogle-search-bar">
        <div class="maxwidth-theme" >
            <div class="col-md-12">
                <div class="search-wrapper">
                    <div id="title-search">
                        <form action="#" class="search">
                            <div class="search-input-div">
                                <input class="search-input" id="title-search-input" type="text" name="q" value="" placeholder="Search" size="20" maxlength="50" autocomplete="off">
                            </div>
                            <div class="search-button-div">
                                <button class="btn btn-search btn-default bold btn-lg" type="submit" name="s" value="services">Search</button>
                                <span class="close-block inline-search-hide"><span class="svg svg-close close-icons"></span></span> </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="header_wrap visible-lg visible-md title-v3 index">
        <header id="header">
            <div class="header-v4 header-wrapper">
                <div class="logo_and_menu-row container">
                    <div class="logo-row">
                        <div class="maxwidth-theme">
                            <div class="row d-flex justify-content-center justify-content-between">
                                <div class="logo-block col-md-5 col-sm-5 p-0">
                                    <div class="logo">
                                        <a href="javascript: void(0);" @click="gotTo('/')"><img src="img/logo/county_logo.png" alt="e-PayNairobi" class="e-logo mr-3" title="HOME"></a>
                                        <div class="d-flex flex-column">
                                            <h4 class="p-0 mb-1 font-14 fw-semibold">Nairobi County Government</h4>
                                            <span class="p-0 m-0">ePayments Platforms</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="right-icons pull-right float-right ">
                                    <div class="pull-right block-link"></div>
                                    <span><i class="fa icon-font"></i></span>
                                    <div class="pull-right">
                                        <div class="wrap_icon inner-table-block">
                                            <div class="profile-pic d-none logged-in">
                                              <!--  <img src="../images/Invisible-Man-780294.jpg" height="80px" width="80px">-->

                                                <i class="fa fa-angle-down"></i>
                                                <ul class="dropdown">
                                                    <li class="menu_back">
                                                     <!--   <img src="../images/Invisible-Man-780294.jpg" height="40px" width="40px">-->

                                                        <ul>
                                                            <li>email@email.com</li>
                                                            <li>Username</li>
                                                        </ul>
                                                    </li>
                                                    <li> <a class="dark-color" href="#" title="check out your profile details"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Profile</font></font></span> </a> </li>
                                                    <!--my profile links-->
                                                    <li> <a class="dark-color" href="#" title="My ads"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">My Ads</font><span class="count-item">0</span></font></span> </a> </li>
                                                    <!--my profile links-->
                                                    <li> <a class="dark-color" href="#" title="Your Added shops"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">My Shop</font><span class="count-item">0</span></font></span> </a> </li>
                                                    <!--my profile links-->
                                                    <li> <a class="dark-color" href="#" title="Your Favourite Ads"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Favourite Ads</font><span class="count-item">0</span></font></span> </a> </li>
                                                    <!--my profile links-->
                                                    <li> <a class="dark-color" href="#" title="Archived Ads"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Archived Ads</font></font></span> </a> </li>
                                                    <!--my profile links-->
                                                    <li> <a class="dark-color" href="#" title="Pending Approval"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Pending Approval</font><span class="count-item">0</span></font></span> </a> </li>
                                                    <!--my profile links-->
                                                    <li> <a class="dark-color" href="#" title="Close Account"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Close Account</font></font></span> </a> </li>
                                                    <!--my profile links-->
                                                    <li> <a class="dark-color" href="#" title="Close Account"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Log Out</font></font></span> </a> </li>
                                                </ul>
                                                <ul>
                                                    <li>email@email.com</li>
                                                    <li>Username</li>
                                                </ul>
                                            </div>
                                        </div>


                                        <div class="wrap_icon inner-table-block">
                                            <div id="bxdynamic_header-auth-block1_start"></div>
                                            <!-- noindex --><a rel="nofollow" title="log in" class="personal-link dark-color animate-load" data-event="jqm" data-param-type="auth" data-param-backurl="/" data-name="auth" href="signin.html"><i class="svg inline big svg-inline-cabinet" aria-hidden="true" title="log in">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">

                                                <path data-name="Rounded Rectangle 110" class="loccls-1" d="M1433,132h-15a3,3,0,0,1-3-3v-7a3,3,0,0,1,3-3h1v-2a6,6,0,0,1,6-6h1a6,6,0,0,1,6,6v2h1a3,3,0,0,1,3,3v7A3,3,0,0,1,1433,132Zm-3-15a4,4,0,0,0-4-4h-1a4,4,0,0,0-4,4v2h9v-2Zm4,5a1,1,0,0,0-1-1h-15a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h15a1,1,0,0,0,1-1v-7Zm-8,3.9v1.6a0.5,0.5,0,1,1-1,0v-1.6A1.5,1.5,0,1,1,1426,125.9Z" transform="translate(-1415 -111)"></path>
                                            </svg>
                                        </i><span class="wrap"><span class="name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Sign in </font></font></span><span class="title"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">My Account</font></font></span></span></a><!-- /noindex -->
                                            <div id="bxdynamic_header-auth-block1_end" style="display:none"></div>
                                        </div>
                                    </div>
                                    <div class="pull-right">
                                        <div class="wrap_icon inner-table-block btn-span-margin text-capitalize"> <a href="#" ><span id="get-a-receipt">print receipt</span></a> <a href="shop-category.html"><span id="add-shop" class="btn-secondary text-black">create an account</span></a> </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="menu-row middle-block bgcolored ">
                    <div class="maxwidth-theme" style="background-color: #005c96;">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="menu-only">
                                        <nav class="mega-menu sliced ovisible initied">
                                            <div class="table-menu">
                                                <table>
                                                    <tbody>
                                                    <tr>
                                                        <td class="menu-item dropdown catalog wide_menu" style="visibility: visible;"><div class="wrap"> <a class="dropdown-toggle" href="/catalog/">
                                                            <div><font style="vertical-align: inherit;"><font style="vertical-align: inherit;"> Services</font></font>
                                                                <div class="line-wrapper"><span class="line"></span></div>
                                                            </div>
                                                        </a> <span class="tail"></span>
                                                            <ul class="dropdown-menu text-capitalize">
                                                                <li class="dropdown-submenu  has_img">
                                                                    <div class="menu_img"><img src="img/new-icons/case.svg" alt="cars & vehcles" title="cars & vehcles"></div>
                                                                    <div  title="county parking services" class="service-link-title pb-4"> <span class="name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Single Business Permits</font></font> </span> <span class="arrow"><i></i></span></div>
                                                                    <ul class="dropdown-menu toggle_menu text-capitalize">
                                                                        <!--									revenue stream service-->
                                                                        <li class="menu-item "> <a href="renew-biz.html" title="renew your business permits" style="white-space: normal;" > <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">renew SBP</font> </font> </span> </a> </li>
                                                                        <!--									revenue stream service-->
                                                                        <!--									revenue stream service-->
                                                                        <li class="menu-item"> <a href="create-biz.html" title="pay or check for penalties" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">register new business</font> </font> </span> </a> </li>
                                                                        <!--									revenue stream service-->

                                                                        <!--									revenue stream service-->
                                                                        <li class="menu-item"> <a href="print-permit.html" title="print a permit" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">print permit</font> </font> </span> </a> </li>
                                                                        <!--									revenue stream service-->

                                                                        <!--									revenue stream service-->
                                                                        <li class="menu-item"> <a href="#" title="print receipt for specific permit" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">print receipt</font> </font> </span> </a> </li>
                                                                        <!--									revenue stream service-->
                                                                    </ul>
                                                                </li>
                                                                <li class="dropdown-submenu  has_img">
                                                                    <div class="menu_img"><img src="img/new-icons/house.svg" alt="cars & vehcles" title="services to be paid for your land rates"></div>
                                                                    <div  title="county parking services" class="service-link-title pb-4"> <span class="name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">land rates</font></font> </span> <span class="arrow"><i></i></span></div>
                                                                    <ul class="dropdown-menu toggle_menu text-capitalize">
                                                                        <!--									revenue stream service-->
                                                                        <li class="menu-item "> <a href="land-rates.html" title="pay for your land rates" style="white-space: normal;" > <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">pay land rates</font> </font> </span> </a> </li>
                                                                        <!--									revenue stream service-->
                                                                        <!--									revenue stream service-->
                                                                        <li class="menu-item"> <a href="#" class="get-a-receipt" title="print a transaction's receipt" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">print receipt</font> </font> </span> </a> </li>
                                                                        <!--									revenue stream service-->
                                                                    </ul>
                                                                </li>
                                                                <li class="dropdown-submenu  has_img">
                                                                    <div class="menu_img"><img src="img/new-icons/key.svg" alt="cars & vehcles" title="services to be paid for your land rates"></div>
                                                                    <div  title="pay for bills" class="service-link-title pb-4"> <span class="name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">rents</font></font> </span> <span class="arrow"><i></i></span></div>
                                                                    <ul class="dropdown-menu toggle_menu text-capitalize">
                                                                        <!--									revenue stream service-->
                                                                        <li class="menu-item "> <a href="house-rents.html" title="pay for your house rents" style="white-space: normal;" > <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">house rents</font> </font> </span> </a> </li>
                                                                        <!--									revenue stream service-->
                                                                        <!--									revenue stream service-->
                                                                        <li class="menu-item"> <a href=market-rents.html title="pay for your market stalls rental fees" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">market stalls</font> </font> </span> </a> </li>
                                                                        <!--									revenue stream service-->
                                                                        <li class="menu-item"> <a href="#" class="get-a-receipt" title="search for receipt" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">receipt</font> </font> </span> </a> </li>
                                                                        <!--									revenue stream service-->

                                                                    </ul>
                                                                </li>
                                                                <li class="dropdown-submenu  has_img">
                                                                    <div class="menu_img"><img src="img/new-icons/parking.svg" alt="cars & vehcles" title="services to be paid for your land rates"></div>
                                                                    <div  title="county rental fees" class="service-link-title pb-4"> <span class="name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">parking fees</font></font> </span> <span class="arrow"><i></i></span></div>
                                                                    <ul class="dropdown-menu toggle_menu text-capitalize">
                                                                        <!--									revenue stream service-->
                                                                        <li class="menu-item "> <a href="daily-parking.html"  title="pay for your daily parking fees" style="white-space: normal;" > <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">daily parking</font> </font> </span> </a> </li>
                                                                        <!--									revenue stream service-->
                                                                        <!--									revenue stream service-->
                                                                        <li class="menu-item "> <a href="seasonal-parking.html" title="pay parking for a specified period of time" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">seasonal parking</font> </font> </span> </a> </li>
                                                                        <!--									revenue stream service-->
                                                                        <li class="menu-item d-none"> <a href="#" title="pay monthly parking for your sacco fleet" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">sacco parking</font> </font> </span> </a> </li>

                                                                        <!--									revenue stream service-->
                                                                        <li class="menu-item"> <a href="offstreet-parking.html" title="offstreet parking fees" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">offstreet parking</font> </font> </span> </a> </li>
                                                                        <!--									revenue stream service-->
                                                                        <!--									revenue stream service-->
                                                                        <li class="menu-item"> <a href="parking-penalty.html" title="pay for parking penalties" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">penalties</font> </font> </span> </a> </li>
                                                                        <!--									revenue stream service-->
                                                                        <!--									revenue stream service-->
                                                                        <li class="menu-item"> <a href="#" class="get-a-receipt" title="phave a look at some of your parking receipts" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">receipts</font> </font> </span> </a> </li>
                                                                        <!--									revenue stream service-->
                                                                        <!--									revenue stream service-->

                                                                    </ul>
                                                                </li>
                                                                <li class="dropdown-submenu  has_img">
                                                                    <div class="menu_img"><img src="img/new-icons/receipt.svg" alt="cars & vehcles" title="services to be paid for your land rates"></div>
                                                                    <div  title="county parking services" class="service-link-title pb-4"> <span class="name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">county bills</font></font> </span> <span class="arrow"><i></i></span></div>
                                                                    <ul class="dropdown-menu toggle_menu text-capitalize">
                                                                        <!--									revenue stream service-->
                                                                        <li class="menu-item "> <a href="create-bill.html" title="pay for your land rates" style="white-space: normal;" > <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">create food handlers bill</font> </font> </span> </a> </li>
                                                                        <!--									revenue stream service-->

                                                                        <!--									revenue stream service-->
                                                                        <li class="menu-item "> <a href="pay-bill.html" title="pay for your land rates" style="white-space: normal;" > <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">pay for a bill</font> </font> </span> </a> </li>
                                                                        <!--									revenue stream service-->
                                                                        <!--									revenue stream service-->
                                                                        <li class="menu-item"> <a href="#" title="print a transaction's receipt" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">print bill</font> </font> </span> </a> </li>
                                                                        <!--									revenue stream service-->

                                                                    </ul>
                                                                </li>
                                                                <li class="dropdown-submenu  has_img">
                                                                    <div class="menu_img"><img src="img/new-icons/brickwall.svg" alt="cars & vehcles" title="services to be paid for your land rates"></div>
                                                                    <div  title="county construction permits" class="service-link-title pb-4"> <span class="name"><font style="vertical-align: inherit;" class="text-lowercase"><font style="vertical-align: inherit;">e-Construction</font></font> </span> <span class="arrow"><i></i></span></div>
                                                                    <ul class="dropdown-menu toggle_menu text-capitalize">
                                                                        <!--									revenue stream service-->
                                                                        <li class="menu-item "> <a href="#" title="pay for a construction permit" style="white-space: normal;" > <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">pay permit</font> </font> </span> </a> </li>
                                                                        <!--									revenue stream service-->
                                                                        <!--									revenue stream service-->
                                                                        <li class="menu-item"> <a href="#" title="print an e-costruction receipt" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">print receipt</font> </font> </span> </a> </li>
                                                                        <!--									revenue stream service-->

                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                        </div></td>
                                                        <td class="menu-item dropdown" style="visibility: visible;"><div class="wrap"> <a class="dropdown-toggle" href="#/">
                                                            <div><font style="vertical-align: inherit;"><font style="vertical-align: inherit;"> Help </font></font>
                                                                <div class="line-wrapper"><span class="line"></span></div>
                                                            </div>
                                                        </a> <span class="tail"></span>
                                                            <ul class="dropdown-menu text-capitalize">
                                                                <li class="  "> <a href="#" title="FAQs"><span class="name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">FAQs</font></font></span></a> </li>
                                                                <li class="  "> <a href="/help/delivery/" title="Tearms Of Use"><span class="name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">guidlines</font></font></span></a> </li>
                                                            </ul>
                                                        </div></td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="line-row visible-xs"></div>
            </div>
        </header>
    </div>
    <div class="jqmOverlay search"></div>
    <div id="headerfixed">
        <div class="maxwidth-theme container">
            <div class="logo-row v2 row margin0 menu-row">
                <div class="inner-table-block nopadding logo-block">
                    <div class="logo"> <a href="javascript: void(0);" @click="gotTo('/')"><img src="img/logo/county_logo.png" alt="Kisumu ePayment Platform" title="Epayments" height="88px"></a> </div>
                </div>
                <div class="inner-table-block menu-block" style="width: 70%;">
                    <div class="navs table-menu js-nav opacity1" >
                        <nav class="mega-menu sliced ovisible initied">
                            <div class="table-menu">
                                <table>
                                    <tbody>
                                    <tr>
                                        <td class="menu-item dropdown catalog wide_menu" style="visibility: visible;"><div class="wrap"> <a class="dropdown-toggle" href="#">
                                            <div><font style="vertical-align: inherit;"><font style="vertical-align: inherit;"> Services</font></font>
                                                <div class="line-wrapper"><span class="line"></span></div>
                                            </div>
                                        </a> <span class="tail"></span>
                                            <ul class="dropdown-menu text-capitalize">
                                                <li class="dropdown-submenu  has_img">
                                                    <div class="menu_img"><img src="img/new-icons/case.svg" alt="cars & vehcles" title="cars & vehcles"></div>
                                                    <div  title="county parking services" class="service-link-title pb-4"> <span class="name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Single Business Permits</font></font> </span> <span class="arrow"><i></i></span></div>
                                                    <ul class="dropdown-menu toggle_menu text-capitalize">
                                                        <!--									revenue stream service-->
                                                        <li class="menu-item "> <a href="renew-biz.html" title="renew your business permits" style="white-space: normal;" > <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">renew SBP</font> </font> </span> </a> </li>
                                                        <!--									revenue stream service-->
                                                        <!--									revenue stream service-->
                                                        <li class="menu-item"> <a href="#" title="pay or check for penalties" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">register new business</font> </font> </span> </a> </li>
                                                        <!--									revenue stream service-->

                                                        <!--									revenue stream service-->
                                                        <li class="menu-item"> <a href="#" title="print a permit" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">print permit</font> </font> </span> </a> </li>
                                                        <!--									revenue stream service-->

                                                        <!--									revenue stream service-->
                                                        <li class="menu-item"> <a href="#" title="print receipt for specific permit" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">print receipt</font> </font> </span> </a> </li>
                                                        <!--									revenue stream service-->
                                                    </ul>
                                                </li>
                                                <li class="dropdown-submenu  has_img">
                                                    <div class="menu_img"><img src="img/new-icons/house.svg" alt="cars & vehcles" title="services to be paid for your land rates"></div>
                                                    <div  title="county parking services" class="service-link-title pb-4"> <span class="name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">land rates</font></font> </span> <span class="arrow"><i></i></span></div>
                                                    <ul class="dropdown-menu toggle_menu text-capitalize">
                                                        <!--									revenue stream service-->
                                                        <li class="menu-item "> <a href="land-rates.html" title="pay for your land rates" style="white-space: normal;" > <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">pay land rates</font> </font> </span> </a> </li>
                                                        <!--									revenue stream service-->
                                                        <!--									revenue stream service-->
                                                        <li class="menu-item"> <a href="#" class="get-a-receipt" title="print a transaction's receipt" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">print receipt</font> </font> </span> </a> </li>
                                                        <!--									revenue stream service-->
                                                    </ul>
                                                </li>
                                                <li class="dropdown-submenu  has_img">
                                                    <div class="menu_img"><img src="img/new-icons/key.svg" alt="cars & vehcles" title="services to be paid for your land rates"></div>
                                                    <div  title="pay for bills" class="service-link-title pb-4"> <span class="name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">rents</font></font> </span> <span class="arrow"><i></i></span></div>
                                                    <ul class="dropdown-menu toggle_menu text-capitalize">
                                                        <!--									revenue stream service-->
                                                        <li class="menu-item "> <a href="house-rents.html" title="pay for your house rents" style="white-space: normal;" > <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">house rents</font> </font> </span> </a> </li>
                                                        <!--									revenue stream service-->
                                                        <!--									revenue stream service-->
                                                        <li class="menu-item"> <a href="market-rents.html" title="pay for your market stalls rental fees" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">market stalls</font> </font> </span> </a> </li>
                                                        <!--									revenue stream service-->
                                                        <li class="menu-item"> <a href="#" class="get-a-receipt" title="search for receipt" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">receipt</font> </font> </span> </a> </li>
                                                        <!--									revenue stream service-->

                                                    </ul>
                                                </li>
                                                <li class="dropdown-submenu  has_img">
                                                    <div class="menu_img"><img src="img/new-icons/parking.svg" alt="cars & vehcles" title="services to be paid for your land rates"></div>
                                                    <div  title="county rental fees" class="service-link-title pb-4"> <span class="name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">parking fees</font></font> </span> <span class="arrow"><i></i></span></div>
                                                    <ul class="dropdown-menu toggle_menu text-capitalize">
                                                        <!--									revenue stream service-->
                                                        <li class="menu-item "> <a href="daily-parking.html" title="pay for your daily parking fees" style="white-space: normal;" > <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">daily parking</font> </font> </span> </a> </li>
                                                        <!--									revenue stream service-->
                                                        <!--									revenue stream service-->
                                                        <li class="menu-item"> <a href="seasonal-parking.html" title="pay parking for a specified period of time" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">seasonal parking</font> </font> </span> </a> </li>
                                                        <!--									revenue stream service-->
                                                        <li class="menu-item"> <a href="#" title="pay monthly parking for your sacco fleet" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">sacco parking</font> </font> </span> </a> </li>

                                                        <!--									revenue stream service-->
                                                        <li class="menu-item"> <a href="offstreet-parking.html" title="offstreet parking fees" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">offstreet parking</font> </font> </span> </a> </li>
                                                        <!--									revenue stream service-->
                                                        <!--									revenue stream service-->
                                                        <li class="menu-item"> <a href="parking-penalty.html" title="pay for parking penalties" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">penalties</font> </font> </span> </a> </li>
                                                        <!--									revenue stream service-->
                                                        <!--									revenue stream service-->
                                                        <li class="menu-item"> <a href="#" class="get-a-receipt" title="phave a look at some of your parking receipts" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">receipts</font> </font> </span> </a> </li>
                                                        <!--									revenue stream service-->
                                                        <!--									revenue stream service-->

                                                    </ul>
                                                </li>
                                                <li class="dropdown-submenu  has_img">
                                                    <div class="menu_img"><img src="img/new-icons/receipt.svg" alt="cars & vehcles" title="services to be paid for your land rates"></div>
                                                    <div  title="county parking services" class="service-link-title pb-4"> <span class="name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">county bills</font></font> </span> <span class="arrow"><i></i></span></div>
                                                    <ul class="dropdown-menu toggle_menu text-capitalize">
                                                        <!--									revenue stream service-->
                                                        <li class="menu-item "> <a href="pay-bill.html" title="pay for your land rates" style="white-space: normal;" > <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">pay for a bill</font> </font> </span> </a> </li>
                                                        <!--									revenue stream service-->

                                                        <!--									revenue stream service-->
                                                        <li class="menu-item "> <a href="create-bill.html" title="pay for your land rates" style="white-space: normal;" > <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">create food handlers bill</font> </font> </span> </a> </li>
                                                        <!--									revenue stream service-->

                                                        <!--									revenue stream service-->
                                                        <li class="menu-item"> <a href="#" title="print a transaction's receipt" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">print bill</font> </font> </span> </a> </li>
                                                        <!--									revenue stream service-->

                                                    </ul>
                                                </li>
                                                <li class="dropdown-submenu  has_img">
                                                    <div class="menu_img"><img src="img/new-icons/brickwall.svg" alt="cars & vehcles" title="services to be paid for your land rates"></div>
                                                    <div  title="county construction permits" class="service-link-title pb-4"> <span class="name"><font style="vertical-align: inherit;" class="text-lowercase"><font style="vertical-align: inherit;">e-Construction</font></font> </span> <span class="arrow"><i></i></span></div>
                                                    <ul class="dropdown-menu toggle_menu text-capitalize">
                                                        <!--									revenue stream service-->
                                                        <li class="menu-item "> <a href="#" title="pay for a construction permit" style="white-space: normal;" > <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">pay permit</font> </font> </span> </a> </li>
                                                        <!--									revenue stream service-->
                                                        <!--									revenue stream service-->
                                                        <li class="menu-item"> <a href="#" title="print an e-costruction receipt" style="white-space: normal;"> <span class="name pb-1"> <font style="vertical-align: inherit;"> <font style="vertical-align: inherit;">print receipt</font> </font> </span> </a> </li>
                                                        <!--									revenue stream service-->

                                                    </ul>
                                                </li>
                                            </ul>
                                        </div></td>
                                        <td class="menu-item dropdown" style="visibility: visible;"><div class="wrap"> <a class="dropdown-toggle" href="#">
                                            <div><font style="vertical-align: inherit;"><font style="vertical-align: inherit;"> Help</font></font>
                                                <div class="line-wrapper"><span class="line"></span></div>
                                            </div>
                                        </a> <span class="tail"></span>
                                            <ul class="dropdown-menu text-capitalize">
                                                <li class="  "> <a href="#" title="FAQs"><span class="name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">FAQs</font></font></span></a> </li>
                                                <li class="  "> <a href="/help/delivery/" title="Tearms Of Use"><span class="name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">guidlines</font></font></span></a> </li>
                                            </ul>
                                        </div></td>
                                        <td class="menu-item dropdown js-dropdown nosave" style="display: none; visibility: visible;"><div class="wrap"> <a class="dropdown-toggle more-items" href="#"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Yet</font></font></span> </a> <span class="tail"></span>
                                            <ul class="dropdown-menu">
                                            </ul>
                                        </div></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </nav>
                    </div>
                </div>

                <div class="inner-table-block nopadding small-block">
                    <div class="wrap_icon wrap_cabinet">
                        <div id="bxdynamic_header-auth-block2_start" style="display:none"></div>
                        <!-- noindex --><a rel="nofollow" title="log in" class="personal-link dark-color animate-load" data-event="jqm" data-param-type="auth" data-param-backurl="/" data-name="auth" href="#"><i class="svg inline big svg-inline-cabinet" aria-hidden="true" title="log in">
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
                            <defs>
                              <!--  <style>
                                    .loccls-1 {
                                        fill: #222;
                                        fill-rule: evenodd;
                                    }
                                </style>-->
                            </defs>
                            <path data-name="Rounded Rectangle 110" class="loccls-1" d="M1433,132h-15a3,3,0,0,1-3-3v-7a3,3,0,0,1,3-3h1v-2a6,6,0,0,1,6-6h1a6,6,0,0,1,6,6v2h1a3,3,0,0,1,3,3v7A3,3,0,0,1,1433,132Zm-3-15a4,4,0,0,0-4-4h-1a4,4,0,0,0-4,4v2h9v-2Zm4,5a1,1,0,0,0-1-1h-15a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h15a1,1,0,0,0,1-1v-7Zm-8,3.9v1.6a0.5,0.5,0,1,1-1,0v-1.6A1.5,1.5,0,1,1,1426,125.9Z" transform="translate(-1415 -111)"></path>
                        </svg>
                    </i></a><!-- /noindex -->
                        <div id="bxdynamic_header-auth-block2_end" style="display:none"></div>
                    </div>
                </div>
                <div class="inner-table-block small-block nopadding inline-search-show" data-type_search="fixed">
                    <div class="search-block top-btn"><i class="svg svg-search lg"></i></div>
                </div>
            </div>
        </div>
    </div>
    <div id="mobileheader" class="visible-xs visible-sm">
        <div class="mobileheader-v2">
            <form>
                <!--			mobile search bar-->
                <input type="text" placeholder="search" id="mobile-search-box" class="search-expand">
                <input type="submit">
                <!--			mobile search bar-->
                <!--		mobile search bar quick options -->
                <div class="search-options-panel d-none">
                    <!--				search option item	-->
                    <span class="search-option"> <img src="images/97d3a6a67f5daf82e471d9a4bc44726b.png" width="30px" height="30px"/>
        <p class="option-item"><b>Samsung Galaxy S8 </b><i>Phones & Accessories</i></p>
        </span>
                    <!--				search option item-->
                    <!--				search option item	-->
                    <span class="search-option"> <img src="images/97d3a6a67f5daf82e471d9a4bc44726b.png" width="30px" height="30px"/>
        <p class="option-item"><b>Nikon D40</b><i>Electronics & Gadgets</i></p>
        </span>
                    <!--				search option item-->
                    <!--				search option item	-->
                    <span class="search-option"> <img src="images/97d3a6a67f5daf82e471d9a4bc44726b.png" width="30px" height="30px"/>
        <p class="option-item"><b>Samsung TV 45" </b><i>Electronics & Gadgets</i></p>
        </span>
                    <!--				search option item-->
                    <!--				search option item	-->
                    <span class="search-option"> <img src="images/97d3a6a67f5daf82e471d9a4bc44726b.png" width="30px" height="30px"/>
        <p class="option-item"><b>BMW X6</b><i>Cars & Vehcles</i></p>
        </span>
                    <!--				search option item-->
                    <!--				search option item	-->
                    <span class="search-option"> <img src="images/97d3a6a67f5daf82e471d9a4bc44726b.png" width="30px" height="30px"/>
        <p class="option-item"><b>Canono T3200</b><i>Electronics & Gadgets</i></p>
        </span>
                    <!--				search option item-->
                </div>
                <!--			mobile search bar quick options -->
            </form>
            <i class="txt-box-back text-black"><i data-feather="arrow-left" class="text-black"></i></i> <i class="txt-box-clear"><i data-feather="x" class="text-black"></i></i>
            <div class="burger pull-left"> <i class="svg inline  svg-inline-burger dark svg-inline-cabinet" aria-hidden="true" data-feather="menu"></i> <i class="svg inline  svg-inline-close dark" aria-hidden="true" data-feather="x">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                    <defs>
                       <!-- <style>
                            .cccls-1 {
                                fill: #222;
                                fill-rule: evenodd;
                            }
                        </style>-->
                    </defs>
                    <path data-name="Rounded Rectangle 114 copy 3" class="cccls-1" d="M334.411,138l6.3,6.3a1,1,0,0,1,0,1.414,0.992,0.992,0,0,1-1.408,0l-6.3-6.306-6.3,6.306a1,1,0,0,1-1.409-1.414l6.3-6.3-6.293-6.3a1,1,0,0,1,1.409-1.414l6.3,6.3,6.3-6.3A1,1,0,0,1,340.7,131.7Z" transform="translate(-325 -130)"></path>
                </svg>
            </i> </div>
            <div class="title-block col-sm-6 col-xs-5 pull-left"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Nairobi <small>e-Payments</small></font></font></div>
            <div class="right-icons pull-right">
                <div class="pull-right">
                    <div class="wrap_icon">
                        <button class="top-btn mobile-search-invoke twosmallfont"> <i class="svg inline big text-white" aria-hidden="true" data-feather="search"></i> </button>
                    </div>
                </div>
                <div class="pull-right">
                    <div class="wrap_icon wrap_cabinet">
                        <div id="bxdynamic_header-auth-block3_start" style="display:none"></div>
                        <!-- noindex --><a rel="nofollow" title="log-in" class="personal-link dark-color animate-load" data-event="jqm" data-param-type="auth" data-param-backurl="/" data-name="auth" href="#"> <i class="svg inline big white svg-inline-cabinet text-white" aria-hidden="true" title="log-in" data-feather="power"></i> </a><!-- /noindex -->
                        <div id="bxdynamic_header-auth-block3_end" style="display:none"></div>
                    </div>
                </div>
            </div>
        </div>

        <div id="mobilemenu-overlay"></div>
        <div id="mobilemenu-overlay"></div>
    </div>
    <div id="mobilemenu" class="leftside">
        <div class="mobilemenu-v1 scroller">
            <div class="wrap">
                <div class="prof-container">
                    <div class="prof-bg-img"> <img src="img/bg/nairobibg.jpg"/> </div>
                    <div class="user-dp"><img src="img/user/default-user.png"></div>
                    <p class="mt-2">User Name</p>
                    <p class="email-address">Useremail@email.com</p>
                    <p class="log-out" title="logout"><i class="icon-off"><i data-feather="power"></i></i></p>
                </div>
                <div class="menu top">
                    <ul class="top text-capitalize">
                        <li>
                            <!--my profile-->
                            <a class="dark-color parent" title="How to buy"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">my account</font></font></span> <span class="arrow"><i class="text-black" data-feather="chevron-right"></i></span> </a>
                            <ul class="dropdown">
                                <li class="menu_back"><a  class="dark-color  d-flex align-items-center" rel="nofollow"><i class="text-black" data-feather="arrow-left"></i><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Go back</font></font></a></li>
                                <li class="menu_title"><a href="#"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">My Account</font></font></a></li>
                                <!--my profile links-->
                                <li> <a class="dark-color" href="#" title="check out your profile details"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">my profile</font></font></span> </a> </li>
                                <!--my profile links-->
                                <li> <a class="dark-color" href="#" title="My ads"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">transactions history</font><span class="count-item d-none">0</span></font></span> </a> </li>
                                <!--my profile links-->
                                <li> <a class="dark-color" href="#" title="Your registered assets"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">My assets</font><span class="count-item">0</span></font></span> </a> </li>
                                <!--my profile links-->
                                <li> <a class="dark-color" href="#" title="your created bills"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">my bills</font><span class="count-item">0</span></font></span> </a> </li>
                                <!--my profile links-->
                                <li> <a class="dark-color" href="#" title="change your account's password"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">change password</font></font></span> </a> </li>
                                <!--my profile links-->
                                <li> <a class="dark-color" href="#" title="log out my account"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">log out</font><span class="count-item d-none">0</span></font></span> </a> </li>
                            </ul>
                        </li>
                        <li class="selected"> <a class="dark-color parent"  title="Catalog"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">single business permit</font></font></span> <span class="arrow"><i class="text-black" data-feather="chevron-right"></i></span> </a>
                            <ul class="dropdown">
                                <li class="menu_back"><a  class="dark-color  d-flex align-items-center" rel="nofollow"><i class="text-black" data-feather="arrow-left"></i><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Go back</font></font></a></li>
                                <li class="menu_title"><a href="#"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">single business permit</font></font></a></li>
                                <li> <a class="dark-color" href="#" title="renew permit for an existing business"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">renew permit</font></font></span></a> </li>
                                <!--category-->
                                <li> <a class="dark-color" href="#" title="register a new business"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">register new business</font></font></span></a> </li>
                                <!--category-->
                            </ul>
                        </li>
                        <li> <a class="dark-color parent" title="How to buy"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">land rates</font></font></span> <span class="arrow"><i class="text-black" data-feather="chevron-right"></i></span> </a>
                            <ul class="dropdown">
                                <li class="menu_back"><a  class="dark-color  d-flex align-items-center" rel="nofollow"><i class="text-black" data-feather="arrow-left"></i><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Go back</font></font></a></li>
                                <!--				land rate sub menu-->
                                <li class="menu_title"><a href="#"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">land rates</font></font></a></li>
                                <!--				land rate sub menu-->
                                <li> <a class="dark-color" href="#" title="pay for your land rates"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">pay land rate</font></font></span> </a> </li>
                                <!--				land rate sub menu-->
                                <li> <a class="dark-color" href="#" title="print a land rate receipt"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">print land rate</font></font></span> </a> </li>
                                <!--				land rate sub menu-->
                            </ul>
                        </li>
                        <li> <a class="dark-color parent" title="county rental fees"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">rents</font></font></span> <span class="arrow"><i class="text-black" data-feather="chevron-right"></i></span> </a>
                            <ul class="dropdown">
                                <li class="menu_back"><a  class="dark-color  d-flex align-items-center" rel="nofollow"><i class="text-black" data-feather="arrow-left"></i><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Go back</font></font></a></li>
                                <!--				rents ssub menu-->
                                <li class="menu_title"><a href="#"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">rents</font></font></a></li>

                                <!--				rents ssub menu-->
                                <li> <a class="dark-color" href="#" title="pay for house rents"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">house rents</font></font></span> </a> </li>
                                <!--				rents ssub menu-->
                                <li> <a class="dark-color" href="#" title="pay for stall rents"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">stall rents</font></font></span> </a> </li>
                                <!--				rents ssub menu-->
                                <li> <a class="dark-color" href="#" title="print a receipt"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">receipts</font></font></span> </a> </li>
                            </ul>
                        </li>
                        <li> <a class="dark-color parent" title="Company"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">parking</font></font></span> <span class="arrow"><i class="text-black" data-feather="chevron-right"></i></span> </a>
                            <ul class="dropdown">
                                <li class="menu_back"><a  class="dark-color  d-flex align-items-center" rel="nofollow"><i class="text-black" data-feather="arrow-left"></i><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Go back</font></font></a></li>
                                <!--				rents ssub menu-->
                                <li class="menu_title"><a href="#"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">parking</font></font></a></li>
                                <!--				rents ssub menu-->
                                <li> <a class="dark-color" href="#" title="pay for your daily parking"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">daily parking</font></font></span> </a> </li>
                                <!--				rents ssub menu-->
                                <li> <a class="dark-color" href="#" title="parking fee for specified period of time"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">seasonal parking</font></font></span> </a> </li>

                                <!--				rents ssub menu-->
                                <li> <a class="dark-color" href="#" title="parking for a fleet of cars"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">sacco parking</font></font></span> </a> </li>

                                <!--				rents ssub menu-->
                                <li> <a class="dark-color" href="#" title="offstreet parking"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">offstreet parking</font></font></span> </a> </li>

                                <!--				rents ssub menu-->
                                <li> <a class="dark-color" href="#" title="parking penalties"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">penalties</font></font></span> </a> </li>

                                <!--				rents ssub menu-->
                                <li> <a class="dark-color" href="#" title="transactions receipts"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">parking receipts</font></font></span> </a> </li>
                            </ul>
                        </li>
                        <li> <a class="dark-color parent" title="county bills"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">county bills</font></font></span> <span class="arrow"><i class="text-black" data-feather="chevron-right"></i></span> </a>
                            <ul class="dropdown">
                                <li class="menu_back"><a  class="dark-color  d-flex align-items-center" rel="nofollow"><i class="text-black" data-feather="arrow-left"></i><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Go back</font></font></a></li>
                                <!--				bills ssub menu-->
                                <li class="menu_title"><a href="#"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">county bills</font></font></a></li>

                                <!--				bills ssub menu-->
                                <li> <a class="dark-color" href="#" title="pay for an already created county bill"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">pay for a bill</font></font></span> </a> </li>
                                <!--				bills  ssub menu-->
                                <li> <a class="dark-color" href="#" title="print a bill"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">print a bill</font></font></span> </a> </li>
                                <!--				bills ssub menu-->

                            </ul>
                        </li>
                        <li> <a class="dark-color parent" title="construction permits"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">e-Construction</font></font></span> <span class="arrow"><i class="text-black" data-feather="chevron-right"></i></span> </a>
                            <ul class="dropdown">
                                <li class="menu_back"><a  class="dark-color  d-flex align-items-center" rel="nofollow"><i class="text-black" data-feather="arrow-left"></i><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Go back</font></font></a></li>
                                <!--				bills ssub menu-->
                                <li class="menu_title"><a href="#"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">e-Construction</font></font></a></li>

                                <!--				bills ssub menu-->
                                <li> <a class="dark-color" href="#" title="pay for permit"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">pay for permit</font></font></span> </a> </li>
                                <!--				bills  ssub menu-->
                                <li> <a class="dark-color" href="#" title="print transactions document"> <span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">print permit</font></font></span> </a> </li>
                                <!--				bills ssub menu-->

                            </ul>
                        </li>
                    </ul>
                    <!--my profile-->

                </div>
            </div>
        </div>
    </div>

    <div id="mobilemenu-overlay"></div>
    <section class="account-pop-up h-100vh w-100 justify-content-center align-items-center animated d-none" id="search-receipt">
        <div class="container p-lg-5 p-md-0">
            <div class="row p-5">
                <div class="receipt-search-container m-5 d-flex flex-column-md animated" id="get-receipt-container">
                    <div class="col-lg-6 receipt-img-container">
                        <div class="col-lg-8 col-md-12 position-relative p-5">
                            <h2 class="mb-4 text-capitalize text-white">print a receipt for a transaction</h2>
                            <p class="font-14 mb-3 text-capitalize">Make sure all inputs are correct. The process has been made this way to help protect personal data</p>
                        </div>
                    </div>
                    <div class="col-lg-6 p-5 position-relative"> <span class="close-receipt-form position-absolute"> <i data-feather="x"></i></span>
                        <form class="transaction-info">
                            <div class="">
                                <h4 class="mb-5 pb-2 text-capitalize">correctly fill in the form bellow to continue</h4>
                            </div>
                            <div class="row m-0">
                                <div  class="form-group col-sm-12 pl-md-0">
                                    <label for="sel1"><small>What was the transacton type?</small></label>
                                    <select class="form-control mb-3" id="sel1" required>
                                        <option>Parking</option>
                                        <option>Business</option>
                                        <option class="text-capitalize">County bills</option>
                                        <option class="text-capitalize">land rates</option>
                                        <option class="text-capitalize">rents</option>
                                        <option class="text-capitalize">e-Construction</option>
                                        <option></option>
                                    </select>
                                </div>
                            </div>
                            <div class="row radio-container m-0">
                                <div class="col-12 pl-md-0 mb-3">
                                    <div class="form-check-inline">
                                        <label class="form-check-label">M-Pesa
                                            <input type="radio" class="form-check-input" name="method" required>
                                            <span class="checkmark"></span> </label>
                                    </div>
                                    <div class="form-check-inline">
                                        <label class="form-check-label">NBK
                                            <input type="radio" class="form-check-input" name="method" required>
                                            <span class="checkmark"></span> </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row m-0">
                                <div class="form-group col-sm-12 pl-md-0 mb-3">
                                    <label for="exampleInputEmail1" class="text-capitalize"><small>what was the refference number for the transaction?</small></label>
                                    <input type="text" class="form-control" id="fname" placeholder="eg M-Pesa ref number" required>
                                </div>
                                <div class="form-group col-sm-12 pl-md-0 mb-3">
                                    <label for="identifier" class="text-capitalize"><small>Unique identifire for transaction eg Number plate, bill number, Business ID etc</small></label>
                                    <input type="text" class="form-control" id="identifier" placeholder="identifier" required>
                                </div>
                                <button type="submit" class="btn btn-primary text-capitalize text-white font-14 submit-btn">get receipt</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--form section-->
    <!--	pay confirmation pop up-->
    <section class="account-pop-up h-100vh w-100 justify-content-center align-items-center animated d-none" id="pay-confirmation-pop">
        <div class="container p-md-5 p-sm-0">
            <div class="row p-5 d-flex justify-content-center">
                <div class="col-sm-12 col-md-6 col-lg-5 payment-pop-container m-5 m-sm-3 d-flex flex-column-md animated p-0">

                    <div class="col-12 position-relative p-0">
                        <span class="close-receipt-form position-absolute z-index-1 d-none transactions-actions animated text-white" id="close-pricepop"> <i data-feather="x"></i></span>

                        <div class="">
                            <div class="show-amount-loader">
                                <center class="p-5 text-white">
                                    <div class="lds-roller animated"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                    <div class="confirmed-pay d-none animated"><img src="img/icons/transactions-icons/check.svg"></div>
                                    <p class="text-center text-white m-0 p-0 mb-3  payment-status">pending daily parking payment</p>
                                    <h2 class="mb-5 pb-5 text-capitalize text-white"><sup class="font-14 text-uppercase">Kes</sup>2,000</h2>
                                </center>
                            </div>
                            <div class="col-12 p-lg-5 p-sm-3 ">
                                <div class="col-12 p-0 transacton-instructions">
                                    <h5><strong class="text-capitalize pb-3">payment procedure</strong></h5>
                                    <p class="font-12">follow the following payment procedure in order to complete the payment</p>

                                    <hr>
                                    <ul type="1" class="font-14 pb-5">
                                        <li>1.Check on a <strong>payment pop </strong>up on your phone.</li>
                                        <li>2.Input your <strong>M-pesa pin</strong> and click OK.</li>
                                        <li>3.An <strong>MPESA</strong> confimation SMS will be sent to you.</li>
                                        <li>4.Wait for upto <strong>45 secs</strong> as we process your transaction</li>
                                    </ul>
                                </div>


                                <div class="col-12 p-0 pb-3 d-none transactions-actions animated mt-5">
                                    <h5><strong class="text-capitalize pb-3">Thank You!</strong></h5>
                                    <p class="font-14 text-capitalize m-0">Payment was succesfully received.</p>
                                    <p class="font-14">you can close the pop up or perform the actions bellow</p>
                                    <a href="#" class="btn btn-primary px-5 py-3 text-white text-uppercase font-12 font-sm-10 px-sm-4">Print Receipts</a>
                                    <a href="#" class="btn btn-secondary px-5 py-3 text-black text-uppercase font-12 font-sm-10 px-sm-4">add to assets</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section>
    <!--	pay confirmation pop up-->
    <section id="service-form-section" class="">
        <div class="container">
            <div class="row p-5 ">
                <div class="service-form-container  d-flex flex-column-md animated col-12 p-0">
                    <div class="col-lg-7 service-form-img-container" id="biz-img">
                        <div class="col-lg-8 col-md-12 position-relative p-5">
                            <h2 class="mb-4 text-capitalize text-white">NCCC Business Permit</h2>
                            <p class="font-14 mb-3 text-capitalize">fill in and remember to double check details to avoid any errors.</p>
                        </div>
                    </div>
                    <div class="col-lg-5 p-5 position-relative transactions-form-container d-flex">



                        <div class="col-12  p-0 animated details-confirm">
                            <div class="">
                                <p class="mb-5 pb-2 text-capitalize">
                                    <span class="back-toform" title="back to transactions form"><i data-feather="arrow-left"  class="mr-3 float-left"></i></span><strong>confirm your transaction details</strong>
                                </p>
                                <hr class="mt-1 p-0">
                                <p><strong class="text-capitalize">buiness details</strong></p>

                                <div class="transactions-details-container text-capitalize">
                                    <p class="m-0"><span>business name</span></p>
                                    <strong>{{business.businessName}}</strong>
                                    <hr>
                                    <p class="m-0"><span>business ID</span></p>
                                    <strong>{{business.businessID}}</strong>
                                    <hr>

                                   <!-- <p class="m-0"> <span>Business activities</span></p>
                                    <b>{{business.businessSubCategory}}</b>
                                    <hr>-->

                                 <!--   <p class="m-0"> <span>last renewal date</span></p>-->
                                  <!--  <strong class="text-uppercase">{{business.lastRenewed}}</strong>-->
                                    <hr>
                                    <div class=" text-right amount-container">
                                        <p :key="index" :value="item" v-for="(item, index) in feesAndCharges">
                                            <span class="text-left">{{item.feeDescription}}</span> <strong> KES {{numberFormat(item.unitFeeAmount)}}</strong>
                                        </p>
                                    </div>
                                    <hr>

                                    Total Amount <strong>KES {{numberFormat(totalAmount)}}</strong>

                                    <form>

                                        <div class="form-group col-sm-12 col-md-12 p-0">
                                            <label for="number-plate" class="text-capitalize">amount to pay</label>
                                            <input v-model="totalAmount" type="text" class="form-control" id="amount" placeholder="Enter amount to pay" readonly>

                                        </div>

                                        <div class="form-group col-sm-12 col-md-12 p-0">
                                            <label for="mpesa-number" class="text-capitalize">M-pesa phone number</label>
                                            <input v-model="phoneNumber" type="text" class="form-control" id="mpesa-number" placeholder="eg 0712...">
                                        </div>

                                        <div class="col-12 p-0 text-uppercase mt-5">
                                            <button type="button" class="btn btn-primary text-white font-14 w-100 p-4 center mx-0 confirm-btn">
                                                <div class="btn-txt animated">
                                                    <span @click="generateBill()" class="btn-text text-uppercase font-12">pay now kes {{numberFormat(totalAmount)}}</span>
                                                    <i data-feather="arrow-right" class="ml-2 float-right pull-right">
                                                    </i>
                                                </div>
                                                <div v-if="loading" class="lds-ellipsis animated"><div></div><div></div><div></div><div></div></div>
                                            </button>
                                        </div>

                                        <div class="col-12 p-0 text-uppercase mt-5">
                                            <button type="button" class="btn btn-primary text-white font-14 w-100 p-4 center mx-0 confirm-btn">
                                                <div class="btn-txt animated">
                                                    <span @click="generateBill()" class="btn-text text-uppercase font-12">PRINT INVOICE kes {{numberFormat(totalAmount)}}</span>
                                                    <i data-feather="arrow-right" class="ml-2 float-right pull-right">
                                                    </i>
                                                </div>
                                                <div v-if="loading" class="lds-ellipsis animated"><div></div><div></div><div></div><div></div></div>
                                            </button>
                                        </div>

                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!--	pay confirmation pop up-->
    <section v-if="payConfirmationPop" class="account-pop-up h-100vh w-100 justify-content-center align-items-center animated">
        <div class="container p-md-5 p-sm-0">
            <div class="row p-5 d-flex justify-content-center">
                <div class="col-sm-12 col-md-6 col-lg-5 payment-pop-container m-5 m-sm-3 d-flex flex-column-md animated p-0">

                    <div class="col-12 position-relative p-0">
                        <span class="close-receipt-form position-absolute z-index-1 d-none transactions-actions animated text-white" id="close-pricepop"> <i data-feather="x"></i></span>

                        <div class="">
                            <div class="show-amount-loader">
                                <center class="p-5 text-white">
                                    <div v-if="loadingPayment" class="lds-roller animated"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                    <div v-if="paymentReceived" class="confirmed-pay animated"><img src="img/icons/transactions-icons/check.svg"></div>
                                    <p v-if="!paymentReceived" class="text-center text-white m-0 p-0 mb-3  payment-status">pending payment</p>
                                    <h2 class="mb-5 pb-5 text-capitalize text-white"><sup class="font-14 text-uppercase">Kes</sup>{{numberFormat(totalAmount)}}</h2>
                                </center>
                            </div>
                            <div class="col-12 p-lg-5 p-sm-3 ">
                                <div v-if="!paymentReceived" class="col-12 p-0 transacton-instructions">
                                    <h5><strong class="text-capitalize pb-3">payment procedure</strong></h5>
                                    <p class="font-12">follow the following payment procedure in order to complete the payment</p>

                                    <hr>
                                    <ul type="1" class="font-14 pb-5">
                                        <li>1.Check on a <strong>payment pop </strong>up on your phone.</li>
                                        <li>2.Input your <strong>M-pesa pin</strong> and click OK.</li>
                                        <li>3.An <strong>MPESA</strong> confimation SMS will be sent to you.</li>
                                        <li>4.Wait for upto <strong>45 secs</strong> as we process your transaction</li>
                                    </ul>
                                </div>

                                <div v-if="paymentReceived" class="col-12 p-0 pb-3 transactions-actions animated mt-5">
                                    <h5><strong class="text-capitalize pb-3">Thank You {{transaction.names}}!</strong></h5>
                                    <p class="font-14 text-capitalize m-0">Payment was successfully received. <strong>{{transaction.transaction_code}}</strong></p>
                                    <p class="font-14">you can close the pop up or perform the actions bellow</p>
                                    <a @click="printReceipt()" href="javascript: void(0);" class="btn btn-primary px-5 py-3 text-white text-uppercase font-12 font-sm-10 px-sm-4">Print Receipts</a>
                                    <!--<a href="#" class="btn btn-secondary px-5 py-3 text-black text-uppercase font-12 font-sm-10 px-sm-4">add to assets</a>-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--	pay confirmation pop up-->
    <!--form section-->
    <section id="download" class="clearfix parallax-section">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-center">
                    <h2>NCCG e-Payments Platforms</h2>
                </div>
            </div>
            <!-- row -->

            <!-- row -->
            <div class="row">
                <!-- download-app -->
                <div class="col-sm-4"> <a href="https://play.google.com/store/apps/details?id=com.aw.epayments" class="download-app mb-2"> <img src="img/logo/google-play.png" alt="Image" class="img-responsive"> <span class="pull-left"> <span>available on</span> <strong class="text-white">Google Play</strong> </span> </a> </div>
                <!-- download-app -->

                <!-- download-app -->
                <div class="col-sm-4"> <a href="#" class="download-app mb-2"> <img src="img/logo/ussd.svg" alt="Image" class="img-responsive"> <span class="pull-left"> <span>USSD services</span> <strong class="text-white">*235#</strong> </span> </a> </div>
                <!-- download-app -->

                <!-- download-app -->
                <div class="col-sm-4"> <a href="#" class="download-app mb-2"> <img src="img/logo/web.svg" alt="Image" class="img-responsive"> <span class="pull-left"> <span>available on</span> <strong class="text-white">Web Portal</strong> </span> </a> </div>
                <!-- download-app -->
            </div>
            <!-- row -->
        </div>
        <!-- contaioner -->
    </section>
    <!-- download -->
    <!--			footer-->
    <footer id="footer" class="clearfix">
        <!-- footer-top -->
        <section class="footer-top clearfix bg-dark text-capitalize">
            <div class="container">
                <div class="row">
                    <!-- footer-widget -->
                    <div class="col-sm-3">
                        <div class="footer-widget news-letter mb-5">
                            <div class="">
                                <h3 class="text-white text-capitalize">Nairobi County Government Self Service Portal</h3>
                                <p class="font-12">Make payments easily and quickly. Create account and get to enjoy paying for Kisumu City County services online.</p>
                            </div>
                            <img src="img/logo/county_logo.png" height="60px;" class="pr-2"> </div>
                    </div>
                    <!-- footer-widget -->

                    <!-- footer-widget -->
                    <div class="col-sm-3">
                        <div class="footer-widget mb-5">
                            <h3 class="text-white">My account</h3>
                            <ul>
                                <li><a href="#">my profile</a></li>
                                <li><a href="#">transactions history</a></li>
                                <li><a href="#">my assets</a></li>
                                <li><a href="#">my bills</a></li>
                                <li><a href="#">Change Password</a></li>
                                <li><a href="#">log out</a></li>
                            </ul>
                        </div>
                    </div>
                    <!-- footer-widget -->

                    <!-- footer-widget -->
                    <div class="col-sm-3">
                        <div class="footer-widget social-widget mb-5">
                            <h3 class="text-white">quick links</h3>
                            <ul>
                                <li><a href="daily-parking.html">daily parking</a></li>
                                <li><a href="parking-penalty.html">parking penalties</a></li>
                                <li><a href="pay-bill.html">pay for bills</a></li>
                                <li><a href="seasonal-parking.html">seasonal parking</a></li>
                                <li><a href="#">e-construction</a></li>
                                <li><a href="#">FAQ</a></li>
                            </ul>
                        </div>
                    </div>
                    <!-- footer-widget -->

                    <!-- footer-widget -->
                    <div class="col-sm-3">
                        <div class="footer-widget news-letter mb-5">
                            <h3 class="text-white text-capitalize">Android app</h3>
                            <p class="font-12">Download The Mobile App From Google Playstore And Get To Enjoy County Services At The Tip Of Your Fingers</p>
                            <a href="https://play.google.com/store/apps/details?id=com.aw.epayments" class="p-3"> <img src="img/logo/google-play-66442243.svg" alt="Google Play Store Badge" class="FooterNew-appStoreIcon-image"> </a>
                            <!-- form -->
                            <form action="" class="d-none">
                                <input type="email" class="form-control" placeholder="Your E-mail">
                                <button type="submit" class="btn btn-primary">Subscribe</button>
                            </form>
                            <!-- form -->
                        </div>
                    </div>
                    <!-- footer-widget -->
                </div>
                <!-- row -->
            </div>
            <!-- container -->
        </section>
        <!-- footer-top -->

        <div class="footer-bottom clearfix  bg-dark">
            <div class="container">
                <p class="d-none">Copyright © 2018 Kemiio </p>
                <div class="row">
                    <div class="col-6 p-md-0 px-sm-3">
                        <p class="text-capitalize m-0 text-white font-12">Help Line</p>
                        <h4 class="SiteStat-value text-white">+254 704 549 859</h4>
                    </div>
                    <div class="col-6 px-sm-3 p-md-0 d-none"> <img src="img/logo/powered_nbk_center.png" class="float-right" height="35px"> </div>
                </div>
            </div>
        </div>
        <!-- footer-bottom -->
    </footer>
    <!--			footer-->


    </body>
    </html>

</template>

<script>
    import {biller, execute, execute2, paysol, trade} from "@/api";
    export default {
        name: "BusinessPayment",
        data() {
            return {
                phoneNumber:'',
                payConfirmationPop: false,
                loading: false,
                loadingPayment: false,
                paymentReceived: false,
                billGenerated: {
                    billNo: null,
                    payBillNo: null,
                    amount: null,
                    success: null
                },

                date: null,
                receiptDetails: {
                    id: null,
                    receiptNo: null,
                    billNo: null,
                    detailAmount: null,
                    receiptAmount: null,
                    billBalance: null,
                    costCenterNo: null,
                    accountNo: null,
                    incomeTypeDescription: null,
                    feeID: null,
                    wardID: null,
                    subCountyID: null,
                    currency: null,
                    source: null,
                    transactionCode: null,
                    paidBy: null,
                    dateCreated: null,
                    dateModified: null,
                    createdBy: null,
                    modifiedBy: null,
                    isActive: null,
                    status: null,
                },
                link: '',
                receiptInfos: [{
                    id: null,
                    receiptNo: null,
                    billNo: null,
                    feeID: null,
                    feeDescription: null,
                    billTotal: null,
                    receiptAmount: null,
                    billBalance: null,
                    customer: null,
                    clientPhoneNo: null,
                    description: null,
                    receiptDate: null,
                    printCount: null,
                    wardID: null,
                    subCountyID: null,
                    subCountyName: null,
                    wardName: null,
                    dateCreated: null,
                    dateModified: null,
                    createdBy: null,
                    modifiedBy: null,
                    printedBy: null,
                    updated: null,
                    isActive: null,
                    status: null,
                    zone: null,
                    departmentID: null,
                    department: null,
                    idNo: null,
                    phoneNumber: null,
                    names: null,
                    customerPhoneNumber: null,
                    category: null,
                    incomeTypePrefix: null
                }],
                county: {
                    id: null,
                    countyID: null,
                    countyName: null,
                    tagline: null,
                    smsusername: null,
                    smspassword: null,
                    contact: null,
                    center: null,
                    address: null,
                    email: null,
                    headline: null,
                    tagline2: null,
                    ussd: null,
                    logo: null,
                    bank: null,
                    powered: null,
                    stateLogo: null,
                    seal: null,
                    signature: null,
                    lat: null,
                    lng: null,
                    link: null,
                    paysol: null,
                    poweredByLogo: null,
                    mobileApp: null,
                    selfService: null
                },
                business: {
                    id: null,
                    businessID: null,
                    businessName: null,
                    subCountyID: null,
                    subCountyName: null,
                    wardID: null,
                    wardName: null,
                    plotNumber: null,
                    physicalAddress: null,
                    buildingName: null,
                    buildingOccupancy: null,
                    floorNo: null,
                    roomNo: null,
                    premiseSize: null,
                    numberOfEmployees: null,
                    tonnage: null,
                    businessDes: null,
                    businessCategory: null,
                    businessSubCategory: null,
                    incomeTypeID: null,
                    feeID: null,
                    businessEmail: null,
                    postalAddress: null,
                    postalCode: null,
                    businessPhone: null,
                    contactPersonNames: null,
                    contactPersonIDNo: null,
                    businessRole: null,
                    contactPersonPhone: null,
                    contactPersonEmail: null,
                    fullNames: null,
                    ownerID: null,
                    ownerPhone: null,
                    ownerEmail: null,
                    kraPin: null,
                    createdBy: null,
                    createdByIDNo: null,
                    dateCreated: null,
                    lat: null,
                    lng: null,
                    lastRenewed: null,
                    permitsCount: null,
                    conservancy: null,
                    fireLicence: null,
                    liquorLicence: null,
                    businessType: null
                },
                feesAndChargeItems:[{
                    feeId: null,
                    feeDescription: null,
                    unitOfMeasure: null,
                    unitFeeAmount: null,
                    accountNo: null,
                    incomeTypeId: null,
                    feeType: null,
                    accountDesc:null ,
                    costCenterNo:null,
                    zone: null,
                    typeDescription: null,
                    customer: null
                }],
                feesAndCharges:[
                    {
                        feeId: null,
                        feeDescription: null,
                        unitOfMeasure: null,
                        unitFeeAmount: null,
                        accountNo: null,
                        incomeTypeId: null,
                        feeType: null,
                        accountDesc: null,
                        costCenterNo: null,
                        zone: null,
                        typeDescription: null,
                        prorated: null,
                        duration: null,
                        brimsCode: null
                    }

                ],
                subCounty:{},
                ward:{},
                billItem:[],
                totalAmount:0,
                transaction: {
                    id: null,
                    account_to: null,
                    account_from: null,
                    amount: null,
                    ref: null,
                    transaction_code: null,
                    payment_mode: null,
                    company: null,
                    status: null,
                    merchant_request_id: null,
                    date: null,
                    balance: null,
                    names: null,
                    email: null,
                    posted: null,
                    postedToEric: null,
                    TransactionDesc: null,
                    zone: null,
                    street: null,
                    user_id: null,
                    username: null,
                    user_full_name: null,
                    msisdn: null,
                    postedToBiller: null,
                    queried: null,
                    postedToHealth: null,
                    currency: null,
                    message: null,
                    response: null,
                    remarks: null
                },



            };
        },
        mounted() {

            let params = {}
            try {
                params = JSON.parse(localStorage['params'])
                this.getBusiness(params.businessID);
            } catch (error) {
                // ignore
            }

          /*  this.getBusiness('4218')*/

        },
        methods: {
            showDate() {
                const dateObj = new Date();
                const currentDate =
                    dateObj.getDate() +
                    "/" +
                    dateObj.getMonth() +
                    "/" +
                    dateObj.getFullYear();
                return currentDate;
            },
            numberFormat(num) {
                const britishNumberFormatter = new Intl.NumberFormat("en-GB");
                return britishNumberFormatter.format(num)
            },
            getReceipt(receiptNo) {
                const data = new FormData();
                data.append("function", "getReceipt");
                data.append("receiptNo", receiptNo);


                execute(data, biller)
                    .then((res) => {
                        if (res.data.success) {
                            this.receiptDetails = res.data.data.receiptDetails;
                            this.receiptInfos = res.data.data.receiptInfos;
                            this.county = res.data.data.county;
                            this.link = "https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=" + this.receiptDetails.receiptNo;
                        } else {
                            alert(res.data.data.message);
                        }
                    })
                    .catch((e) => {
                        alert(e.message);
                    });
            },
            getBusiness(businessID){
                const data = new FormData();
                data.append("function", "getBusiness");
                data.append("businessID", businessID);
                execute(data,trade)
                    .then((res) =>{
                        if (res.data.success) {

                            this.business = res.data.data.business
                            this.feesAndCharges = res.data.data.feesAndCharges

                            this.subCounty.subCountyID = this.business.subCountyID
                            this.subCounty.subCountyName =  this.business.subCountyName
                            this.ward.wardID =  this.business.wardID
                            this.ward.wardName = this.business.wardName

                            this.feesAndChargeItems.splice(0)
                            //Set fee and charge
                            this.feesAndChargeItems = res.data.data.feesAndCharges

                            this.feesAndChargeItems = this.feesAndChargeItems.map(item => {
                                if (item.feeId === this.business.feeID) {
                                    item.customer = "Business ID: " + this.business.businessID;
                                }else {
                                    item.customer =""
                                }
                                return item;
                            });

                            this.addToBill(this.business.businessName)


                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },
            addToBill(revenueStreamItem){


                for (const { typeDescription,feeId, feeDescription, unitFeeAmount, accountNo, costCenterNo, incomeTypeId,customer} of this.feesAndChargeItems) {
                    this.totalAmount += parseFloat(unitFeeAmount);

                    this.billItem.push({
                        fiscalYear: this.fiscalYear,
                        typeDescription,
                        feeDescription,
                        unitFeeAmount: unitFeeAmount,
                        accountNo,
                        costCenterNo,
                        incomeTypeId,
                        revenueStreamItem: revenueStreamItem,
                        feeId,
                        amount:  unitFeeAmount,
                        customer: customer,
                        zone: sessionStorage.getItem("zone"),
                        subCountyID:this.subCounty.subCountyID,
                        subCountyName:this.subCounty.subCountyName,
                        wardID: this.ward.wardID,
                        wardName: this.ward.wardName,
                        idNo: sessionStorage.getItem("idNo"),
                        phoneNumber: sessionStorage.getItem("phoneNumber"),
                        names: sessionStorage.getItem("names"),
                        customerPhoneNumber: this.phoneNumber,
                        description: this.description,
                    });
                }
            },
            generateBill(){
                this.loading = true
                const data = new FormData();
                data.append("function", "generateBill3");
                data.append("billItem", JSON.stringify(this.billItem));
                execute(data,biller)
                    .then((res) =>{
                        this.loading = false
                        this.message =res.data.message
                        if (res.data.success) {
                            this.billGenerated = res.data.data.billGenerated
                            this.customerPayBillOnline()
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },
            customerPayBillOnline() {
                this.payConfirmationPop = true;
                this.loadingPayment = true;

                const data = new FormData();
                data.append("function", "customerPayBillOnline");
                data.append("payBillNumber", this.billGenerated.payBillNo);
                data.append("amount", "1");
                data.append("accountReference", this.billGenerated.billNo);
                data.append("transactionDesc", this.billGenerated.billNo);
                data.append("phoneNumber", this.phoneNumber);
                data.append("token", "im05WXYH2rwRruPjCICieOs8m4E8IoltnDEhyPUv6bnB9cU60gD48SnJPC6oh7EpsPaAUGC8wqIdtVVjGlWLxqFssshxMHxHjEQJ");
                execute2(data, paysol)
                    .then((res) => {
                        if (res.data.success) {
                            // Call checkPayment every 2 seconds until it returns true
                            const intervalId = setInterval(() => {
                                this.checkPayment()
                                    .then((result) => {
                                        if (result) {
                                            // If the condition is true, clear the interval
                                            clearInterval(intervalId);
                                        }
                                    })
                                    .catch((error) => {
                                        console.error(error);
                                    });
                            }, 2000);
                        } else {
                            alert(res.data.message);
                        }
                    })
                    .catch((e) => {
                        // Handle error
                        this.message = e.message;
                    });
            },
            checkPayment() {
                this.loadingPayment = true;
                const data = new FormData();
                data.append("function", "checkPayment");
                data.append("accountRef", this.billGenerated.billNo);

                return new Promise((resolve, reject) => {
                    execute(data, biller)
                        .then((res) => {
                            if (res.data.success) {
                                this.loadingPayment = false;
                                this.transaction = res.data.data.transaction;
                                this.receiptDetails = res.data.data.receipt.receiptDetails
                                this.paymentReceived = true
                                resolve(true);

                            } else {
                                resolve(false);
                                // reject(new Error(res.data.message));
                            }
                        })
                        .catch((error) => {
                            reject(error);
                        });
                });
            },
            printReceipt() {
                if (this.receiptDetails.receiptNo == null) {
                    alert("Try again later");
                } else {
                    //  this.$refs.closeReceived.click();
                    localStorage['params'] = JSON.stringify({
                        receiptNo: this.receiptDetails.receiptNo
                    })
                    const routeData = this.$router.resolve({name: 'receipt'});
                    window.open(routeData.href, '_blank');
                }
            },
            gotTo(route){
                sessionStorage.setItem("selectedSub",route)
            }
        },
    };
</script>

<style scoped>

</style>