<template>

    <head>
        <meta charset="utf-8">
        <title class="">Single business permit</title>
        <link href="https://fonts.googleapis.com/css?family=Aldrich|Fira+Sans:200,300,400,500,700,800,900|Norican&display=swap&subset=cyrillic" rel="stylesheet">
        <link href="https://fonts.googleapis.com/css?family=Libre+Baskerville:400,400i,700|Open+Sans:300,400,600,700&display=swap" rel="stylesheet">

    </head>

    <body>
    <page size="B4" class="p-2 container w-100 h-100">
        <section class="content-container h-100 w-100 position-relative p-2">
            <header>
                <section class="container p-0 m-0">
                    <div class="row">

                        <!--				logo container-->
                        <div class="col-12">
                            <div class=""> <img  src="../permit/img/biz-header.png" class="w-100"> </div>
                        </div>

                        <hr class="mb-0">
                        <!--				control number and issue date container-->
                        <div class="col-12 d-flex mt-2">

                            <div class="col-6">
                                <div>
                                    <p class="text-left">Date of Issue :<span class="libre-bold">{{permit.dateOfIssue}}</span> </p>
                                </div>
                            </div>
                            <div class="col-6">
                                <div>
                                    <p class="float-right dropright text-right"> <span class="other-num text-right col-auto right p-0 serial-init">Single Business Permit For The Year: <strong class="serial">2024</strong></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </header>
            <section class="container p-3 m-0">
                <div class="row">
                    <div class="col-12 ">
                        <p class="text-left"><span class="libre-bold">Nairobi City County grants this business permit to</span></P>
                    </div>
                    <div class="col-8">
                        <table class="table table-bordered mb-0">
                            <tbody>
                            <!--				the name to the busibness-->
                            <tr>
                                <td><p class="p-0 m-0">Applicant/Business/Commercial Name</p>
                                    <p class="text-uppercase libre-bold m-0 p-0 title-font">{{permit.businessName}}</p></td>
                            </tr>


                            <!--				the businness ID-->
                            <tr>
                                <td class="text-capitalize serial-init ">Pin Number: <span class="serial">{{permit.kraPin}}</span></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-4 mb-1">
                        <div class="locence-no max-height d-flex h-100 justify-content-center align-items-center align-content-center p-2 flex-column">
                            <p class="serial-init">Business ID</p>
                            <p> <span class="serial-init font-22">LID <span class="serial">{{permit.businessID}}</span></span></p>
                        </div>
                    </div>
                    <div class="col-8 mt-4">
                        <table class="table table-bordered table-striped">
                            <tbody>
                            <tr>
                                <td colspan="2"><p class="text-uppercase p-0 m-0">To engage in the activity/Business/Profession or Occupation of</p>
                                    <p class="text-uppercase libre-bold m-0 p-0 serial m-0">{{permit.businessCategory}}</p>
                                </td>
                            </tr>

                            <tr>
                                <td class="">{{permit.businessSubCategory}}</td>
                            </tr>


                            </tbody>
                        </table>
                    </div>
                    <div class="col-4 mt-4">
                        <div class="activity-code-bg max-height d-flex h-100 justify-content-center align-items-center align-content-center p-2 flex-column">
                            <p class="serial-init">Activity Code</p>
                            <p> <span class="serial-init font-22">LID <span class="serial">405</span></span></p>
                        </div>
                    </div>

                    <div class="col-12 mt-4">
                        <table class="table table-bordered table-striped">
                            <tbody>
                            <tr>
                                <td class="text-uppercase">Having paid a business permit fee of</td>
                                <td class="title-font"> Ksh {{permit.receiptAmount}}</td>
                            </tr>
                            <tr>
                                <td  class="other-num serial-init">*** {{formatAmount(permit.receiptAmount)}} ***</td>
                                <td class="text-uppercase">Payment Plan: <span class="libre-bold">12 Months</span></td>
                            </tr>

                            </tbody>
                        </table>
                    </div>


                    <div class="col-12 mt-4">
                        <table class="table table-bordered table-striped">
                            <tbody>
                            <tr class="locence-no">
                                <div class="">
                                    <td class="locence-no" colspan="2">Valid From : <span class="libre-bold locence-no p-1">{{permit.startDate}}</span></td>
                                    <td class="locence-no">Valid To : <span class="libre-bold locence-no p-1">{{permit.endDate}}</span></td>
                                </div>
                            </tr>
                            <tr>
                                <td colspan="3">Business under this permit shall be conducted at the address as indicated below</td>
                            </tr>
                            <tr>
                                <td colspan="2">P.O. Box : <span class="libre-bold">{{permit.postalAddress}}</span></td>
                                <td>Plot No. : <span class="libre-bold title-font">{{permit.plotNumber}}</span></td>
                            </tr>
                            <tr>
                                <td colspan="3">Road Street : <span class="title-font">{{permit.physicalAddress}}</span></td>
                            </tr>
                            <tr>
                                <td>Building : <span class="libre-bold">{{permit.buildingName}}</span></td>
                                <td>Floor : <span class="libre-bold">{{permit.floorNo}}</span></td>
                                <td>Door/Stall No. : <span class="libre-bold">{{permit.roomNo}}</span></td>
                            </tr>
                            <tr>
                                <td colspan="3"> This Permit Is for : <span class="libre-bold">Trade License</span></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="col-8 mt-4">
                        <h3 class="text-left libre-bold exep">Notice</h3>
                        <span class="danger-line"></span>
                        <p class="text-left libre-it mt-2"> It is an offence to give false information.  </p>
                        <ol class="libre-it d-none">
                            <li>from monday to friday during the hours of <b>5:00 PM to 11:00 PM</b></li>
                            <li>during <b> weekends and public holidays</b> during the hours of <b>2:00 PM to 11:00 PM</b></li>
                            <li>on any day of week at any hour to persons taking meals in the restaurant, for consumption with such meals.</li>
                        </ol>
                    </div>

                    <div class="col-4 d-flex justify-content-center align-items-center mt-2">
                        <div>
                            <img src="../permit/img/aapproved.png" class="the-seal">
                        </div>
                    </div>
                </div>
            </section>
            <div class="container position-absolute footer-container">
                <div class="row">
                    <div class="col-8">
                        <div class="row">
                            <div class="col-12"> <img class="mb-2 float-left theqr" :src="link"> </div>
                        </div>
                        <hr class="mt-0 mb-1 mb-5">
                        <p class="text-left">Granting this permit does not exempt the business identified above from complying with the current regulation on health and safety as established by the Government of Kenya and Nairobi City county</p>
                    </div>
                </div>
            </div>
            <img :src="county.poweredByLogo" class="nbk">
        </section>
    </page>

    </body>

</template>

<script>
    import {execute, trade} from "@/api";

    export default {
        name: "PrintPermit",
        data() {
            return{
                message: null,
                link:'',
                county: {
                    id: null,
                    countyID: null,
                    countyName: null,
                    tagline:null,
                    smsusername: null,
                    smspassword:null,
                    contact: null,
                    center: null,
                    address:null,
                    email: null,
                    headline: null,
                    tagline2:null,
                    ussd: null,
                    logo:null,
                    bank: null,
                    powered: null,
                    stateLogo: null,
                    seal: null,
                    signature: null
                },
                permit: {
                    id: null,
                    billNo: null,
                    businessID: null,
                    businessName: null,
                    duration: null,
                    billTotal: null,
                    receiptAmount: null,
                    billBalance: null,
                    startDate: null,
                    endDate: null,
                    statusID: null,
                    status: null,
                    printable: null,
                    issuingOfficer: null,
                    designation: null,
                    dateOfIssue: null,
                    stageStatus: null,
                    comments: null,
                    incomeTypeId: null,
                    feeID: null,
                    subCountyName: null,
                    wardName: null,
                    dateCreated: null,
                    subCountyID: null,
                    wardID: null,
                    plotNumber: null,
                    physicalAddress: null,
                    buildingName: null,
                    buildingOccupancy: null,
                    floorNo: null,
                    roomNo: null,
                    premiseSize: null,
                    numberOfEmployees: null,
                    tonnage: null,
                    businessDes: null,
                    businessCategory: null,
                    businessSubCategory: null,
                    incomeTypeID: null,
                    businessEmail: null,
                    postalAddress: null,
                    postalCode: null,
                    businessPhone: null,
                    contactPersonNames: null,
                    contactPersonIDNo: null,
                    businessRole: null,
                    contactPersonPhone: null,
                    contactPersonEmail: null,
                    fullNames: null,
                    ownerID: null,
                    ownerPhone: null,
                    ownerEmail: null,
                    kraPin: null,
                    createdBy: null,
                    createdByIDNo: null,
                    lat: null,
                    lng: null,
                    lastRenewed: null,
                    permitsCount: null,
                    conservancy: null,
                    fireLicence: null,
                    liquorLicence: null,
                    businessType: null
                }
            }
        },
        mounted() {

            let params = {};
            try {
                const storedData = localStorage.getItem('params');

                if (storedData) {
                    params = JSON.parse(storedData);
                    console.log('####' + params.businessID);
                    this.printTradePermit(params.businessID);
                } else {
                    console.error('No data found in localStorage');
                }
            } catch (error) {
                console.error('Error parsing JSON:', error);
            }
        },
        methods:{
            formatAmount(amount) {
                // Convert numeric amount to words
                const words = this.numberToWords(amount);
                return `${words} Shillings`;
            },
            numberToWords(number) {
                const units = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
                const teens = ['Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
                const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

                const convert = (num) => {
                    if (num < 10) return units[num];
                    if (num < 20) return teens[num - 11];
                    if (num < 100) return `${tens[Math.floor(num / 10)]} ${units[num % 10]}`;
                    if (num < 1000) return `${units[Math.floor(num / 100)]} Hundred ${convert(num % 100)}`;
                    if (num < 1000000) return `${convert(Math.floor(num / 1000))} Thousand ${convert(num % 1000)}`;
                    return 'Number too large';
                };

                return convert(Math.floor(number));
            },
            printTradePermit(businessID){

                const data = new FormData();
                data.append("function", "printTradePermit");
                data.append("businessID", businessID);
                execute(data,trade)
                    .then((res) =>{
                        if (res.data.success) {
                            this.permit = res.data.data.permit
                            this.county = res.data.data.county

                            this.link = "https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=Business ID: "+this.permit.businessID+" |\n "
                                +this.permit.businessName+" | start date: "+ this.permit.startDate+" | Expiry:"+this.permit.endDate;
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },
        }
    }
</script>


<style scoped>
    @charset "utf-8";
    :root {
        --blue: #007bff;
        --indigo: #6610f2;
        --purple: #6f42c1;
        --pink: #e83e8c;
        --red: #dc3545;
        --orange: #fd7e14;
        --yellow: #ffc107;
        --green: #28a745;
        --teal: #20c997;
        --cyan: #17a2b8;
        --white: #fff;
        --gray: #6c757d;
        --gray-dark: #343a40;
        --primary: #007bff;
        --secondary: #6c757d;
        --success: #28a745;
        --info: #17a2b8;
        --warning: #ffc107;
        --danger: #dc3545;
        --light: #f8f9fa;
        --dark: #343a40;
        --breakpoint-xs: 0;
        --breakpoint-sm: 576px;
        --breakpoint-md: 768px;
        --breakpoint-lg: 992px;
        --breakpoint-xl: 1200px;
        --font-family-sans-serif: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        --font-family-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
    }
    .w-100 {
        width: 100%!important;
    }
    img {
        vertical-align: middle;
        border-style: none;
    }
    *, ::after, ::before {
        box-sizing: border-box;
    }
    body {
        margin: 0;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        text-align: left;
        background-color: #fff;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
    }

    .container {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }
    @media (min-width: 992px)
        .container {
            max-width: 960px;
        }
        .text-right {
            text-align: right!important;
        }
        .p-0 {
            padding: 0!important;
        }
        .text-right {
            text-align: right!important;
        }
        .float-right {
            float: right!important;
        }
        .dropdown, .dropleft, .dropright, .dropup {
            position: relative;
        }
        p {
            margin-bottom: 7.5px;
        }
        p {
            margin-top: 0;
            margin-bottom: 1rem;
        }

        .mb-5{

        }
        .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
            position: relative;
            width: 100%;
            padding-right: 15px;
            padding-left: 15px;
        }
        .col-6 {
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            max-width: 50%;
        }
        .col-12 {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }
        .d-flex {
            display: -ms-flexbox!important;
            display: flex!important;
        }
        .mt-2, .my-2 {
            margin-top: .5rem!important;
        }
        .mb-0, .my-0 {
            margin-bottom: 0!important;
        }
        hr {
            margin-top: 1rem;
            margin-bottom: 1rem;
            border: 0;
            border-top: 1px solid rgba(0,0,0,.1);
        }
        .p-3 {
            padding: 1rem!important;
        }
        .m-0 {
            margin: 0!important;
        }
        .text-left {
            text-align: left!important;
        }
        .p-2 {
            padding: .5rem!important;
        }
        .h-100 {
            height: 100%!important;
        }
        .align-content-center {
            -ms-flex-line-pack: center!important;
            align-content: center!important;
        }
        .align-items-center {
            -ms-flex-align: center!important;
            align-items: center!important;
        }
        .justify-content-center {
            -ms-flex-pack: center!important;
            justify-content: center!important;
        }
        .flex-column {
            -ms-flex-direction: column!important;
            flex-direction: column!important;
        }
        .table-bordered {
            border: 1px solid #dee2e6;
        }
        .table {
            width: 100%;
            margin-bottom: 1rem;
            color: #212529;
        }
        table {
            border-collapse: collapse;
        }
        .table td {
            padding: 7.5px;
        }
        .table {
            width: 100%;
            margin-bottom: 0px;
            color: #212529;
        }
        @media (min-width: 768px)
            .container {
                max-width: 720px;
            }
            table {
                border-collapse: collapse;
            }
            .table td, .table th {
                padding: .75rem;
                vertical-align: top;
                border-top: 1px solid #dee2e6;
            }
            .table-bordered td, .table-bordered th {
                border: 1px solid #dee2e6;
            }
            .table td {
                padding: 7.5px;
            }
            .mt-4, .my-4 {
                margin-top: 1.5rem!important;
            }
            .col-10 {
                -ms-flex: 0 0 83.333333%;
                flex: 0 0 83.333333%;
                max-width: 83.333333%;
            }
            .col-8 {
                -ms-flex: 0 0 66.666667%;
                flex: 0 0 66.666667%;
                max-width: 66.666667%;
            }
            .col-4 {
                -ms-flex: 0 0 33.333333%;
                flex: 0 0 33.333333%;
                max-width: 33.333333%;
            }
            .h3, h3 {
                font-size: 1.75rem;
            }
            h1, h2, h3, h4, h5, h6 {
                margin-top: 0;
                margin-bottom: .5rem;
            }
            .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
                margin-bottom: .5rem;
                font-weight: 500;
                line-height: 1.2;
            }
            .h3, h3 {
                font-size: 1.75rem;
            }
            .row {
                display: -ms-flexbox;
                display: flex;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                margin-right: -15px;
                margin-left: -15px;
            }
            .position-absolute {
                position: absolute!important;
            }
            .position-relative{
                position: relative !important;
            }
            .d-none {
                display: none!important;
            }

            @media (min-width: 768px)
                .container {
                    max-width: 720px;
                }


                /*	my styles*/

                /* CSS Document */
                body {
                    font-family: 'Open Sans', sans-serif;
                    font-size: 14px;
                }
                html, body {
                    width: 250mm;
                    height: 353mm;
                }
                .content-container {
                    background:url("img/nrbbg.jpg");
                    /* background-attachment: fixed; */
                    background-size: cover;
                    background-repeat: no-repeat;
                }
                .serial {
                    /*
                        font-family: 'Aldrich', sans-serif !important;
                        font-family: Impact, Haettenschweiler, "Franklin Gothic Bold", "Arial Black", "sans-serif";
                    */

                }
                .title-font {
                    font-family: "Flamante SemiSlab Medium", "Flamante SemiSlab Bold" !important;
                    font-weight: bold;
                    font-family: "night train 315" !important;
                    font-family: ClementFivecleme !important;
                }
                body {
                    background: rgb(204,204,204);
                }
                page {
                    display: block;
                    margin: 0 auto;
                    margin-bottom: 0.5cm;
                    box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);
                    background: repeating-linear-gradient( 45deg, #039840, #039840 10px, #067332 10px, #067332 20px );
                }
                page[size="A4"] {
                    width: 21cm;
                    height: 29.7cm;
                }
                page[size="B4"] {
                    width: 250mm;
                    height: 353mm;
                }
                page[size="A4"][layout="portrait"] {
                    width: 29.7cm;
                    height: 21cm;
                }
                .theqr {
                    width: 90px;
                }
                .libre-bold {
                    font-family: 'Libre Baskerville', serif;
                    font-weight: bold;
                }
                .libre-reg {
                    font-family: 'Libre Baskerville', serif;
                    font-weight: 300;
                }
                .a4-size {
                    width: 595px;
                    height: 842px;
                 /*   background: url("img/Group 31.jpg");*/
                    /* background-attachment: fixed; */
                    background-size: cover;
                    background-repeat: no-repeat;
                }
                .qr-container {
                    width: 105px;
                    height: 105px;
                    border: 1px solid black;
                }
                p {
                    margin-bottom: 7.5px;
                }
                h4 {
                    font-size: 15px;
                    font-weight: bold;
                }
                header h3 {
                    font-size: 27px;
                    font-weight: bold;
                    text-transform: uppercase;
                    white-space: nowrap;
                }
                header h5 {
                    font-size: 20px;
                    font-weight: bold;
                    font-family: 'Libre Baskerville', serif;
                    text-transform: capitalize;
                    white-space: nowrap;
                }
                .duration {
                    padding: 7.5px;
                    border: 1px solid black;
                }
                .table td {
                    padding: 7.5px;
                }
                ol {
                    list-style-type: lower-alpha;
                }
                .green {
                    display: block;
                    height: 8px;
                    color: green;
                    width: 100%;
                    background: #00421B;
                    border-bottom: 2px solid #FFDE00;
                    margin-bottom: 5px;
                }
                .note {
                    font-size: 12px;
                }
                .nbk {
                    float: right;
                    height: 52px;
                    position: absolute;
                    right: 15px;
                    bottom: 20px;
                }
                .exep {
                    color: #dc3545;
                }
                .danger-line {
                    background: repeating-linear-gradient( 45deg, #ff0101, #ff0101 10px, #dc3545 10px, #dc3545 20px );
                    width: 100%;
                    height: 3px;
                    display: block;
                }
                .libre-it {
                    font-family: 'Libre Baskerville', serif;
                    font-style: italic;
                    font-size: 14px;
                    text-transform: capitalize;
                }
                .locence-no {
                    background: #00ff3a59 !important;
                }
                .footer-container {
                    position: absolute;
                    bottom: 15px;
                    font-size: 8px;
                    position: absolute;
                    bottom: 15px;
                    font-size: 8px;
                    padding-top: 15px !important;
                }
                .the-seal {
                    width: 200px;
                    transform: rotate(15deg);
                }
                .serial-init {
                    font-family: "Flamante SemiSlab Medium", "Flamante SemiSlab Bold" !important;
                }
                .serial {
                    font-family: Roman !important;
                    font-family: ClementFivecleme !important;
                    font-weight: bold;
                }
                .font-22 {
                    font-size: 22px;
                }

                .activity-code-bg{
                    background: #fbc01378 !important;
                }

                @media print {
                    body, page {
                        margin: 0 !important;
                        padding: 0 !important;
                        box-shadow: none;
                        font-size: 16px;
                    }
                    .content-container {
                        width: 100%;
                        width: 250mm;
                        height: 353mm;
                        height: 353mm;
                    }
                    html, body {
                        width: 250mm !important;
                        height: 353mm !important;
                        background: white;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin: 0px !important;
                        padding: 0px !important;
                    }
                    page[size="B4"] {
                        width: 250mm !important;
                        height: 353mm !important;
                        /*    padding: 30mm 30mm 30mm 30mm;*/
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        align-content: center;
                        height: 100% !important;
                        margin: 0px !important;
                        /*
                            height: 100vh !important;
                            width: 100vw !important;
                        */
                    }
                    ol {
                        list-style-type: lower-alpha;
                    }
                    .table-bordered td, .table-bordered th {
                        border: 1px solid black !important;
                    }
                    .table-striped tbody tr {
                        background: transparent !important;
                    }
                    .table-striped tbody tr:nth-of-type(odd) {
                        background-color: rgba(0,0,0,.05) !important;
                    }
                    .title-font {
                        font-size: 28px;
                    }
                    .font-22 {
                        font-size: 24px !important;
                    }
                }
                @page {
                    /*
                        width: 250mm;
                        height: 353mm;
                    */
                    /*   margin: 30mm 30mm 30mm 30mm;*/
                }

    /*	my styles*/
</style>